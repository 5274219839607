/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Button,
} from "reactstrap";

import ReactTooltip from "react-tooltip";
import Loader from 'react-loader-spinner';

import { service } from "services";

class Patients extends React.Component {
  constructor(props) {
    super(props);

    this.getData = this.getData.bind(this);

    this.state = {
        patients: [],
        showForm: false,
        table: false,
        edit_id: 0,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    if (this.props.reload === true) {
      this.getData();
    }
  }

  getData() {
    this.props.reloaded();

    service.getItems('patients').then(
      resp => {
        if (resp.patients) {
          this.setState({patients: resp.patients, table: true});
        }
      }
    );
  }

  render() {
    return (
      <>
      { this.state.table ? 
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">List of patients</CardTitle>
                  <Button
                    className="btn-round"
                    color="alert"
                    type="button"
                    onClick={() => this.props.editHandler(0)}
                  >
                    Add new patient
                  </Button>
                </CardHeader>
                <CardBody>
                  <Table responsive hover>
                    <thead className="text-primary">
                      <tr>
                        <th>Patient</th>
                        <th>OTP</th>
                        <th>Counselor</th>
                        <th className="text-center">Doses remaining</th>
                        <th className="text-center">Active today?</th>
                        <th className="text-center">Skipped dosing?</th>
                        <th className="text-center">Unsecured device?</th>
                        <th className="text-center actionsColumn">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.patients.map((patient, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <img
                                  alt="..."
                                  className="avatar list border-gray"
                                  src={patient.picture ? patient.picture : require("assets/img/user.png")}
                                />
                                {patient.name}
                              </td>
                              <td>{patient.clinic}</td>
                              <td>{patient.counselor}</td>
                              {patient.device_id !== 0 ?
                              <>
                              <td className="text-center">{patient.remaining}</td>
                              <td className="text-center"><div style={{backgroundColor: patient.active_today === 0 ? 'red' : '#4DAC26', height: 20, width: 20, borderRadius: '50%', margin: '0px auto'}}></div></td>
                              <td className="text-center"><div style={{backgroundColor: patient.skipped === 1 ? 'red' : '#4DAC26', height: 20, width: 20, borderRadius: '50%', margin: '0px auto'}}></div></td>
                              <td className="text-center"><div style={{backgroundColor: patient.unsecured === 1 ? 'red' : '#4DAC26', height: 20, width: 20, borderRadius: '50%', margin: '0px auto'}}></div></td>
                              </>
                              :
                              <td colSpan={4} style={{textAlign: 'center'}}>
                                No device assigned
                              </td>
                              }
                              <td className="text-center">
                                <i className="far fa-edit text-gray-dark actions" onClick={() => this.props.editHandler(patient.id)} data-tip data-for={"edit" + index} />
                                <ReactTooltip id={"edit" + index} place="top" effect="solid">Edit {patient.name}</ReactTooltip>
                                { patient.device_id !== 0 &&
                                  <> 
                                    <i className="fas fa-sync-alt text-gray-dark actions" onClick={() => this.props.editHandler(patient.device_id, 'reload')} data-tip data-for={"reload" + index} />
                                    <ReactTooltip id={"reload" + index} place="top" effect="solid">Refill device</ReactTooltip>
                                  </>
                                }
                                <i className="far fa-trash-alt text-gray-dark actions" onClick={() => this.props.deleteHandler(patient.id, 'Delete patient ' + patient.name)} data-tip data-for={"delete" + index} />
                                <ReactTooltip id={"delete" + index} place="top" effect="solid">Delete {patient.name}</ReactTooltip>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
        :
          <Loader
            type="Rings"
            className="loader"
            height={100}
            width={100}

          />
        }
      </>
    );
  }
}

export default Patients;
