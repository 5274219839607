import React from "react";
import '../device/device.css';
import { Button } from 'reactstrap';
import { service } from '../../services';

export default class Device extends React.Component {
    constructor(props) {
        super(props);

        var maxBottles = this.props.maxBottles !== undefined ? this.props.maxBottles : 12;
        var loadedBottles = this.props.loadedBottles !== undefined ? this.props.loadedBottles : 12;
        var positions = this.props.positions !== undefined ? this.props.positions : [];
        var position = this.props.position !== undefined ? this.props.position : 0;

        var bottles = this.setBottleStates(maxBottles, positions, loadedBottles);
        var angle = (360 / maxBottles);

        this.state = {
            device_id: this.props.device_id,
            dispenserSize: this.props.dispenserSize !== undefined ? this.props.dispenserSize : 300,
            bottleSize: this.props.bottleSize !== undefined ? this.props.bottleSize : 40,
            dispenserVoid: this.props.dispenserVoid !== undefined ? this.props.dispenserVoid : 50,
            lineColor: this.props.lineColor !== undefined ? this.props.lineColor : '#2c2c2c',
            lineWidth: this.props.lineWidth !== undefined ? this.props.lineWidth : 3,
            backgroundColor: this.props.backgroundColor !== undefined ? this.props.backgroundColor : '#2c2c2c',
            dispenserBackgroundColor: this.props.dispenserBackgroundColor !== undefined ? this.props.dispenserBackgroundColor : '#2c2c2c',
            commands: this.props.commands !== undefined ? this.props.commands : true,
            position: position,
            positions: positions,
            bottle: 1,
            maxBottles: maxBottles,
            loadedBottles: loadedBottles,
            angle: angle,
            rotation: 0,
            bottles: bottles,
            inventory: [],
            log: [],
        };

        this.nextBottle = this.nextBottle.bind(this);
        this.prevBottle = this.prevBottle.bind(this);
    }

    componentDidMount() {
        setTimeout(() => this.updateCanvas(this.state.bottles, this.state.angle, this.state.dispenserSize), 100);
    }
  
    componentDidUpdate() {
        var s = this.state;
        var p = this.props;

        var changed = false;
        if (p.device_id !== undefined && p.device_id !== s.device_id) changed = 1;
        if (p.dispenserSize !== undefined && p.dispenserSize !== s.dispenserSize) changed = 1;
        if (p.bottleSize !== undefined && p.bottleSize !== s.bottleSize) changed = 2;
        if (p.dispenserVoid !== undefined && p.dispenserVoid !== s.dispenserVoid) changed = 3;
        if (p.maxBottles !== undefined && p.maxBottles !== s.maxBottles) changed = 4;
        if (p.loadedBottles !== undefined && p.loadedBottles !== s.loadedBottles) changed = 5;
        if (p.lineColor !== undefined && p.lineColor !== s.lineColor) changed = 6;
        if (p.lineWidth !== undefined && p.lineWidth !== s.lineWidth) changed = 7;
        if (p.positions !== undefined && p.positions !== s.positions) changed = 8;
        if (p.position !== undefined && p.position !== s.position) changed = 9;

        if (changed) {
            var device_id = p.device_id !== undefined ? p.device_id : s.device_id;
            var maxBottles = p.maxBottles !== undefined ? p.maxBottles : s.maxBottles;
            var loadedBottles = p.loadedBottles !== undefined ? p.loadedBottles : s.loadedBottles;
            var positions = p.positions !== undefined ? p.positions : s.positions;
            var position = p.position !== undefined ? p.position : s.position;
            var dispenserSize = p.dispenserSize !== undefined ? p.dispenserSize : s.dispenserSize;
            var bottleSize = p.bottleSize !== undefined ? p.bottleSize : s.bottleSize;
            var dispenserVoid = p.dispenserVoid !== undefined ? p.dispenserVoid : s.dispenserVoid;
            var lineColor = p.lineColor !== undefined ? p.lineColor : s.lineColor;
            var lineWidth = p.lineWidth !== undefined ? p.lineWidth : s.lineWidth;

            var bottles = this.setBottleStates(maxBottles, positions, loadedBottles);
            var angle = (360 / maxBottles);

            this.setState({
                device_id: device_id,
                bottle: 1,
                rotation: 0,
                bottles: bottles, 
                angle: angle, 
                dispenserSize: dispenserSize, 
                bottleSize: bottleSize, 
                dispenserVoid: dispenserVoid, 
                maxBottles: maxBottles, 
                loadedBottles: loadedBottles,
                position: position,
                positions: positions,
                lineColor: lineColor,
                lineWidth: lineWidth,
            });
            
            setTimeout(() => this.updateCanvas(bottles, angle, dispenserSize), 50);
            setTimeout(() => this.setRotation(1, 0), 50);
        }
    }

    setBottleStates(maxBottles, positions, loadedBottles) {
        var bottles = [];
        bottles.push({state: '', id: service.createGuid().split("-")[0]});

        for (var i = 0; i < maxBottles - 1; i++) {
            var bottle_state = "unused";

            if (positions && positions[i]) {
                if (positions[i].status === 0) bottle_state = "used";
            } else {
                bottle_state = "na";
            }
            var bottle_id = service.createGuid().split("-")[0];
            bottles.push({state: bottle_state, id: bottle_id});
        }

        return bottles;
    }

    updateCanvas(bottles, angle, dispenserSize) {
        var dispenser_radius_raw = dispenserSize / 2;
        var c = document.getElementById("canvas");
        var ctx = c.getContext("2d");
        ctx.clearRect(0, 0, c.width, c.height);

        bottles.map((bottle, index) => {
            var thisangle = index * angle + angle / 2 - 90;

            var x = dispenser_radius_raw + dispenser_radius_raw *  -Math.cos(thisangle * 0.0174532925);
            var y = dispenser_radius_raw + dispenser_radius_raw *  -Math.sin(thisangle * 0.0174532925);

            ctx.beginPath();
            ctx.moveTo(x, y);
            ctx.lineTo(dispenserSize/2, dispenserSize/2);
            ctx.lineWidth = this.state.lineWidth;
            ctx.strokeStyle = this.state.lineColor;
            ctx.stroke();
            return true;
        })
    }

    nextBottle() {
        var bottles = [...this.state.bottles];
        var log = [...this.state.log];
        var inventory = [...this.state.inventory];

        var current_bottle = bottles[this.state.bottle - 1];
        var current_bottle_state = current_bottle.state;
        var bottle_id = current_bottle.id;

        var bottle = this.state.bottle;
        var log_action = '';

        if (current_bottle_state === 'used') {
            current_bottle_state = 'removed';
            log_action = 'removed';
        } else if (current_bottle_state === 'removed') {
            bottle_id = service.createGuid().split("-")[0];
            current_bottle_state = 'unused';
            log_action = 'loaded';
            inventory.push({bottle_id: bottle_id, position: bottle});
        } else if (current_bottle_state === 'unused' || current_bottle_state === '') {
            bottle += 1;
            if (bottle > this.state.bottles.length) bottle = 1;
            bottle_id = bottles[bottle - 1].id;
            this.setRotation(bottle, 1);
        }

        if (log_action !== '') log.push({ bottle_id: bottle_id, action: log_action, dt: new Date().toDateString(), position: bottle});
        var changed = {log: log, inventory: inventory};

        current_bottle.state = current_bottle_state;
        current_bottle.id = bottle_id;

        this.setState({bottles: bottles, bottle: bottle, log: log, inventory: inventory});
        this.props.onChange(changed);
    }

    prevBottle() {
        var bottle = this.state.bottle - 1;
        if (bottle < 1) bottle = this.state.bottles.length;

        this.setState({bottle: bottle});
        this.setRotation(bottle, -1);
    }

    setRotation(bottle, r) {
        var d = this.state.rotation + this.state.angle * r;

        this.setState({bottle: bottle, rotation: d});

        document.getElementById("dispenser").style.transform = 'rotate(' + d + 'deg)';
    }

    createLine(x1, y1, x2, y2) {
        var a = x1 - x2,
            b = y1 - y2,
            c = Math.sqrt(a * a + b * b);
    
        var sx = (x1 + x2) / 2,
            sy = (y1 + y2) / 2;
    
        var x = sx - c / 2,
            y = sy;
    
        var alpha = Math.PI - Math.atan2(-b, a);
    
        return {x: x, y: y, l: c, a: alpha};
    }

    render() {
        const { bottles, bottle, angle, commands, backgroundColor, dispenserBackgroundColor, rotation, position } = this.state;

        var bottleSize= this.state.bottleSize;
        var dispenserSize = parseInt(this.state.dispenserSize);
        var dispenseWrapperSize = dispenserSize + 20;
        var padding = 3;
        var dispenser_radius = (dispenserSize - bottleSize) / 2 - padding;

        var dispenserVoid = this.state.dispenserVoid;
        var dl_s = dispenserSize / 2 - dispenserVoid / 2;

        var current_bottle_state = bottles[bottle - 1].state;
        var current_bottle_action = 'Next bottle';
        if (current_bottle_state === 'used') current_bottle_action = 'Remove bottle';
        if (current_bottle_state === 'removed') current_bottle_action = 'Load bottle';

        return (
          <div className="dispenser_wrapper" style={{width: dispenseWrapperSize + "px", backgroundColor: backgroundColor}}>
            <div className="dispenser" alt="logo" id="dispenser" style={{height: dispenserSize + "px", width: dispenserSize + "px", backgroundColor: dispenserBackgroundColor}}>
                <div id="bottles">
                {
                    bottles.map((bottle, index) => {
                        var this_angle = - (index - position) * angle - 90;
                        var color = 'gray';
                        if (bottle.state === 'used') color = 'red';
                        if (bottle.state === 'unused') color = 'green';

                        var x = dispenser_radius + dispenser_radius *  -Math.cos(this_angle * 0.0174532925) + padding;
                        var y = dispenser_radius + dispenser_radius *  -Math.sin(this_angle * 0.0174532925) + padding;

                        return (
                            <div key={index}>
                            {
                                bottle.state !== 'removed' ? 
                                <div style={{backgroundColor: color, top: y, left: x, height: bottleSize + "px", lineHeight: bottleSize + "px", width: bottleSize + "px", transform: 'rotate(' + -rotation + 'deg)'}} className="bottle" key={index}>{index}</div>
                                :
                                null
                            }
                            </div>
                        );
                    })
                }
                </div>
                <div className="dispenser_void" id="dispenser_void" style={{height: dispenserVoid + "px", width: dispenserVoid + "px", left: dl_s, top: dl_s, backgroundColor: backgroundColor}}></div>
                <canvas id="canvas" height={dispenserSize} width={dispenserSize} />
            </div>
            {
                commands &&
                <div className="dispenser_commands">
                    <div className="dispenser_position_info">Position {bottle}</div>
                    <Button onClick={this.nextBottle} className="App-link right" color="default">{current_bottle_action}</Button>
                    <div style={{clear: "both"}}></div>
                </div>
            }
          </div>
      )
    }
  }