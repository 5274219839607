import React from "react";

import {
    Row,
    Col,
    FormGroup,
    Input,
    Form,
} from "reactstrap";

import { Line } from "react-chartjs-2";

import { DropdownList } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';

import { service } from "services";

let default_data = {
    hardware_id: '',
    clinics_id: '',
    patients_id: '',
  };

export default class DevicesForm extends React.Component {
    constructor(props) {
        super(props);
  
        const id = this.props.id ? this.props.id : 0;
        this.onChange = this.onChange.bind(this);
        
        this.state = {
          id: id,
          data: { ...default_data },
          clinics: [],
          patients: [],
        };
    }

    componentDidMount() {
        service.getItems('clinics').then(
            resp => {
              if (resp.clinics) {
                this.setState({clinics: resp.clinics});
              }
            }
          );

        service.getItems('patients').then(
            resp => {
              if (resp.patients) {
                this.setState({patients: resp.patients});
              }
            }
          );

        this.getData(this.state.id);
    }

    componentDidUpdate() {
        if (this.props.id !== this.state.id) {
            this.setState({id: this.props.id});
            if (this.props.action === 'edit') {
                this.getData(this.props.id);
            } else {
                this.setState({data: { ...default_data }});
                this.props.setData({ ...default_data });
            }
        }
    }

    getData(id) {
        if (id > 0) {
            service.getItems('devices/' + id).then(
                resp => {
                    if (resp.devices) {
                        this.setState({data: resp.devices[0]});
                        this.props.setData(resp.devices[0]);
                    }
                }
            );
        } else {
            this.setState({data: { ...default_data }});
            this.props.setData({ ...default_data });
        }
    }
    
    onChange(d, m) {
        this.props.onChange(d, m);
        var data = {...this.state.data};
        data[m] = d;
        this.setState({ data: data });
    }
    
    render() {
        const { data } = this.state;

        var dates = [];
        var temperatures = [];
        var humiditys = [];
        var pressures = [];

        var sensors = data.sensors;

        if (sensors) {
          sensors.reverse();

          sensors.map((sensor) => {
            dates.push(sensor.dt);
            temperatures.push(sensor.temperature);
            humiditys.push(sensor.humidity);
            pressures.push(sensor.pressure);

            return true;
          });
        }

        const temperature = {
            data: {
              labels: dates,
              datasets: [
                {
                  data: temperatures,
                  fill: false,
                  borderColor: "#2f7d41",
                  backgroundColor: "transparent",
                  pointBorderColor: "#2f7d41",
                  pointRadius: 2,
                  pointHoverRadius: 4,
                  pointBorderWidth: 4,
                },
              ],
            },
            options: {
              legend: {
                display: false,
                position: "top",
              },
            },
          };

          const humidity = {
            data: {
              labels: dates,
              datasets: [
                {
                  data: humiditys,
                  fill: false,
                  borderColor: "#17a2b8",
                  backgroundColor: "transparent",
                  pointBorderColor: "#17a2b8",
                  pointRadius: 2,
                  pointHoverRadius: 4,
                  pointBorderWidth: 4,
                },
              ],
            },
            options: {
              legend: {
                display: false,
                position: "top",
              },
            },
          };

          const pressure = {
            data: {
              labels: dates,
              datasets: [
                {
                  data: pressures,
                  fill: false,
                  borderColor: "#28a745",
                  backgroundColor: "transparent",
                  pointBorderColor: "#28a745",
                  pointRadius: 2,
                  pointHoverRadius: 4,
                  pointBorderWidth: 4,
                },
              ],
            },
            options: {
              legend: {
                display: false,
                position: "top",
              },
            },
          };

        return (
            <>
                <Form>
                    <Row>
                    <Col md="5">
                        <FormGroup>
                        <label>Hardware Id</label>
                        <Input
                            id="hardware_id"
                            value={data.hardware_id}
                            onChange={(d) => this.onChange(d.currentTarget.value, "hardware_id")}
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup>
                        <label>Clinic</label>
                        <DropdownList
                            id="clinics_id"
                            data={this.state.clinics}
                            valueField='id'
                            defaultValue={data.clinics_id}
                            value={data.clinics_id}
                            onChange={(d) => this.onChange(d.id, "clinics_id")}
                            textField='name'
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                        <label>Patient</label>
                        <DropdownList
                            id="patients_id"
                            data={this.state.patients}
                            valueField='id'
                            defaultValue={data.patients_id}
                            value={data.patients_id}
                            onChange={(d) => this.onChange(d.id, "patients_id")}
                            textField='name'
                            className="inputdata"
                        />
                        </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                        <Col md="4" style={{padding: '40px'}}>
                            <h6>Temperature (°C)</h6>
                            {temperatures.length > 0 ?
                              <Line
                                  data={temperature.data}
                                  options={temperature.options}
                                  width={400}
                                  height={200}
                              />
                              :
                              <div>
                                No data
                              </div>
                            }
                        </Col>
                        <Col md="4" style={{padding: '40px'}}>
                            <h6>Pressure (hPa)</h6>
                            {pressures.length > 0 ?
                              <Line
                                  data={pressure.data}
                                  options={pressure.options}
                                  width={400}
                                  height={200}
                              />
                              :
                              <div>
                                No data
                              </div>
                            }
                        </Col>
                        <Col md="4" style={{padding: '40px'}}>
                            <h6>Humidity (%)</h6>
                            {humiditys.length > 0 ?
                              <Line
                                  data={humidity.data}
                                  options={humidity.options}
                                  width={400}
                                  height={200}
                              />
                              :
                              <div>
                                No data
                              </div>
                            }
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }
};
