/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Button,
} from "reactstrap";

import ReactTooltip from "react-tooltip";
import Loader from 'react-loader-spinner';

import { service } from "services";

class Devices extends React.Component {
  constructor(props) {
    super(props);

    this.getData = this.getData.bind(this);

    this.state = {
        devices: [],
        showForm: false,
        table: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    if (this.props.reload === true) {
      this.getData();
    }
  }

  getData() {
    this.props.reloaded();

    service.getItems('devices').then(
      resp => {
        if (resp.devices) {
          this.setState({devices: resp.devices, table: true});
        }
      }
    );
  }

  render() {
    return (
      <>
      { this.state.table ? 
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Devices deployed</CardTitle>
                  <Button
                    className="btn-round"
                    color="alert"
                    type="button"
                    onClick={() => this.props.editHandler(0)}
                  >
                    Add new device
                  </Button>
                </CardHeader>
                <CardBody>
                  <Table responsive hover>
                    <thead className="text-primary">
                      <tr>
                        <th>Hardware Id</th>
                        <th>OTP</th>
                        <th>Patient</th>
                        <th className="text-center">Doses remaining</th>
                        <th className="text-center">Active today?</th>
                        <th className="text-center">Skipped dosing?</th>
                        <th className="text-center">Unsecured device?</th>
                        <th className="text-center actionsColumn">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.devices.map((device, index) => {
                          return (
                            <tr key={index}>
                              <td>{device.hardware_id}</td>
                              <td>{device.clinic}</td>
                              <td>{device.patient}</td>
                              <td className="text-center">{device.doses}</td>
                              <td className="text-center"><div style={{backgroundColor: device.active_today === 0 ? 'red' : '#4DAC26', height: 20, width: 20, borderRadius: '50%', margin: '0px auto'}}></div></td>
                              <td className="text-center"><div style={{backgroundColor: device.skipped === 1 ? 'red' : '#4DAC26', height: 20, width: 20, borderRadius: '50%', margin: '0px auto'}}></div></td>
                              <td className="text-center"><div style={{backgroundColor: device.unsecured === 1 ? 'red' : '#4DAC26', height: 20, width: 20, borderRadius: '50%', margin: '0px auto'}}></div></td>
                              <td className="text-center">
                                <i className="far fa-edit text-gray-dark actions" onClick={() => this.props.editHandler(device.id)} data-tip data-for={"edit" + index} />
                                <ReactTooltip id={"edit" + index} place="top" effect="solid">Edit {device.hardware_id}</ReactTooltip>
                                <i className="far fa-trash-alt text-gray-dark actions" onClick={() => this.props.deleteHandler(device.id, 'Delete ' + device.hardware_id)} data-tip data-for={"delete" + index} />
                                <ReactTooltip id={"delete" + index} place="top" effect="solid">Delete {device.hardware_id}</ReactTooltip>
                              </td>
                            </tr>    
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
      :
        <Loader
          type="Rings"
          className="loader"
          height={100}
          width={100}

        />
      }
      </>
    );
  }
}

export default Devices;
