export const service = {
    login,
    logout,
    checkLogin,
    createGuid,
    getDateAndTimeNow,
    getSignedHeaders,
    getItems,
    checkLogout,
    deleteItem,
    insertItem,
    b64DecodeUnicode,
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(process.env.REACT_APP_API + 'login', requestOptions)
        .then(response => response.json())
        .then(resp => {
            if (resp.data) {
                localStorage.setItem('access_key', resp.data.api_key);
                localStorage.setItem('secret_key', resp.data.api_secret);
            }
            return resp;
        });
}

function logout() {
    localStorage.removeItem('access_key');
    localStorage.removeItem('secret_key');
}

function checkLogin() {
    var access_key = localStorage.getItem("access_key");
    var secret_key = localStorage.getItem("secret_key");

    const requestOptions = {
        method: 'GET',
        headers: { ...service.getSignedHeaders('GET', 'session') },
        body: null,
      };

      if (access_key && secret_key) {
        return fetch(process.env.REACT_APP_API + 'session', requestOptions)
        .then(response => response.json())
        .then(resp => {
            if (resp.session) {
                return true;
            } else {
                return false;
            }
        });
    } else {
        return false;
    }
}

function checkLogout(code) {
    const logoutcodes = [100001, 10015, 10016];

    if (logoutcodes.includes(code)) {
      setTimeout(function () {
        service.logout();
        window.location.href = '/login';
      }, 1000);
    }
  }

function getItems(path, id) {
    if (id !== undefined) {
        if (id !== 0 && id !== '') {
            path = path.replace('{ID}', id);
        }
    } 

    const requestOptions = {
        method: 'GET',
        headers: { ...getSignedHeaders('GET', path, null, process.env.REACT_APP_URL_PREFIX), 'Accept': 'application/json', 'Content-Type': 'application/json' }
    };

    return fetch(process.env.REACT_APP_API + path, requestOptions).then(handleResponse);
}

function deleteItem(path, id) {
    if (id === undefined) {
        return false;
    } else if (id === 0) {
        return false;
    }

    const requestOptions = {
        method: 'DELETE',
        headers: { ...getSignedHeaders('DELETE', path + '/' + id, {}, process.env.REACT_APP_URL_PREFIX), 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify({})
    };

    return fetch(process.env.REACT_APP_API + path + '/' + id, requestOptions).then(handleResponse);
}

function insertItem(path, id, data, contenttype) {
    contenttype = 'json';
  
      var method = 'POST';
      if (id !== undefined) {
          if (id > 0) {
              path += '/' + id;
              method = 'PUT';
          }
      }
  
      const requestOptions = {
          method: method,
          headers: { ...getSignedHeaders(method, path, data, process.env.REACT_APP_URL_PREFIX), 'Accept': 'application/' + contenttype, 'Content-Type': 'application/' + contenttype },
          body: contenttype === 'json' ? JSON.stringify(data) : data
      };
  
      if (contenttype === 'json') {
        return fetch(process.env.REACT_APP_API + path, requestOptions).then(handleResponse);
      } else {
        return fetch(process.env.REACT_APP_API + path, requestOptions).then(handleResponseXML);
      }
  }

function handleResponse(response) {
    return response.json().then(responseJson => {
        if (responseJson.errorCode > 0) {
            checkLogout(responseJson.errorCode);
            const error = responseJson.errorMessage;
            return Promise.reject(error);
        }

        return responseJson;
    });
}

function handleResponseXML(response) {
    return response.text()
    .then((response) => {
      return response;
    })
  }

function createGuid(){  
    function S4() {  
       return (((1+Math.random())*0x10000)|0).toString(16).substring(1);  
    }  
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0,3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();  
 } 

 function getDateAndTimeNow(d) {
    var currentdate = new Date(); 
    if (d) currentdate = new Date(d);
    
    var datetime = nulling(currentdate.getDate()) + "/"
            + nulling((currentdate.getMonth()+1))  + "/" 
            + currentdate.getFullYear() + " "  
            + nulling(currentdate.getHours()) + ":"  
            + nulling(currentdate.getMinutes()) + ":" 
            + nulling(currentdate.getSeconds());

    return datetime;
 }

 function nulling(v) {
     return (parseInt(v) > 9) ? v : "0" + v;
 }

 function getSignedHeaders(method, u, b) {
    var access_key = localStorage.getItem("access_key");
    var secret_key = localStorage.getItem("secret_key");
    var regionName = 'us-east-1';
    var serviceName = 'execute-api';
    var host = process.env.REACT_APP_HOST;

    var us = u.split("?");
    var path = process.env.REACT_APP_URL_PREFIX + us[0];
    var querystring = "";
    if (us.length === 2) querystring = us[1];

    var crypto = require('crypto-js');
    var amzDate = getAmzDate(new Date().toISOString());
    var authDate = amzDate.split("T")[0];

    var payload = '';
    var hashedPayload = crypto.SHA256(payload).toString();
    if (b) {
      b = JSON.stringify(b);
      hashedPayload = crypto.SHA256(b).toString();
    }

    var canonicalReq =  method + '\n' +
        path + '\n' +
        querystring + '\n' +
        'content-type:application/json\n' +
        'host:' + host + '\n' +
        'x-amz-content-sha256:' + hashedPayload + '\n' +
        'x-amz-date:' + amzDate + '\n' +
        '\n' +
        'content-type;host;x-amz-content-sha256;x-amz-date\n' +
        hashedPayload;

    var canonicalReqHash = crypto.SHA256(canonicalReq).toString();
    var stringToSign =  'AWS4-HMAC-SHA256\n' + amzDate + '\n' + authDate+'/'+regionName+'/'+serviceName+'/aws4_request\n'+ canonicalReqHash;
    var signingKey = getSignatureKey(crypto, secret_key, authDate, regionName, serviceName);
    var authKey = crypto.HmacSHA256(stringToSign, signingKey);

    var authString  = 'AWS4-HMAC-SHA256 ' +
        'Credential='+
        access_key+'/'+
        authDate+'/'+
        regionName+'/'+
        serviceName+'/aws4_request,'+
        'SignedHeaders=content-type;host;x-amz-content-sha256;x-amz-date,'+
        'Signature='+authKey;

    var headers = {
        'Authorization' : authString,
        'Host' : host,
        'x-amz-date' : amzDate,
        'x-amz-content-sha256' : hashedPayload
  };

  return headers;
}

function getSignatureKey(crypto, key, dateStamp, regionName, serviceName) {
    var kDate = crypto.HmacSHA256(dateStamp, "AWS4" + key);
    var kRegion = crypto.HmacSHA256(regionName, kDate);
    var kService = crypto.HmacSHA256(serviceName, kRegion);
    var kSigning = crypto.HmacSHA256("aws4_request", kService);
    return kSigning;
}

function getAmzDate(dateStr) {
    var chars = [":","-"];
    for (var i=0;i<chars.length;i++) {
      while (dateStr.indexOf(chars[i]) !== -1) {
        dateStr = dateStr.replace(chars[i],"");
      }
    }
    dateStr = dateStr.split(".")[0] + "Z";
    return dateStr;
  }

  function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}
