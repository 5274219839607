/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard";
import Audit from "views/Audit";
import Lists from 'components/Lists';

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-layout-11",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/patients",
    name: "Patients",
    icon: "nc-icon nc-circle-10",
    component: Lists,
    layout: "/admin",
  },
  {
    path: "/devices",
    name: "Devices",
    icon: "fas fa-drum-steelpan",
    component: Lists,
    layout: "/admin",
  },
  {
    path: "/clinics",
    name: "Clinics",
    icon: "nc-icon nc-bank",
    component: Lists,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: "fas fa-user-shield",
    component: Lists,
    layout: "/admin",
  },
  {
    path: "/audit",
    name: "Audit trail",
    icon: "far fa-list-alt",
    component: Audit,
    layout: "/admin",
  },
];
export default routes;
