import React from 'react';
import { Redirect } from 'react-router-dom';
import { service } from '../../services';

import "./Login.css";

import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Row,
    Col,
    Button,
    Input,
    FormGroup,
    FormFeedback
  } from "reactstrap";

  import logo from "verinetics.png";

const styles = {
    content: {
        marginTop: "50px",
        padding: "30px 15px",
        minHeight: "calc(100vh - 130px)",
      },
      contentMobile: {
        marginTop: "30px",
        padding: "30px 15px",
        minHeight: "calc(100vh - 130px)",
      },
      languagesContainer: {
        position: 'absolute',
        top: 10,
        right: 20,
    }
};

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            error: '',
            errorshown: false,
            loggedIn: false,
            loggingIn: true,
            disabled: false,
            windowWidth: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleOnFocus = this.handleOnFocus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({windowWidth: document.body.clientWidth});
        window.addEventListener('resize', () => {
          this.setState({windowWidth: document.body.clientWidth})
        });
      }

    handleChange(e) {
        const { id, value } = e.target;
        this.setState({ [id]: value });
    }

    handleOnFocus(e) {
        e.target.removeAttribute('readonly');
    }

    handleSubmit(e) {
        e.preventDefault();

        var username = document.getElementById("username").value;
        var password = document.getElementById("password").value;

        if (username !== '' && password !== '') {
            document.getElementById("submitbutton").blur();
            this.setState({ disabled: true, loggingIn:true, errorshown: false });
            
            service.login(username, password).then(
                user => { 
                    if (user.errorCode > 0) {
                        this.setState({ error: user.errorMessage, disabled: false, errorshown: true });
                    } else {
                        this.setState({ loggedIn: true, disabled: false, errorshown: false });
                    }
                },
                error => {
                    this.setState({ error: error.message, disabled: false, errorshown: true });
                }
            );
        }
    }

    render() {
        const { disabled, errorshown, error, loggedIn } = this.state;

        return (
            <div style={this.state.windowWidth < 960 ? styles.contentMobile : styles.content }>
            <div id="container">
                <div>
            { loggedIn === false ? (
                <form name="form" onSubmit={this.handleSubmit} autoComplete="new-password">
                    <div className="loginlogo">
                        <div style={{clear: 'both'}}></div>
                        <div className="logo-img">
                            <img src={logo} alt="react-logo" className="ck" />
                        </div>
                    </div>
                    <input type="hidden" value="something"/>
                    <Card className="loginCard">
                        <CardHeader>
                            <h4>Login</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                    <label>Username</label>
                                    <Input
                                        id="username"
                                        placeholder=""
                                        type="text"
                                        autoComplete="new-password"
                                        className="inputdata"
                                        invalid={errorshown ? true : false}
                                    />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                    <label>Password</label>
                                    <Input
                                        id="password"
                                        placeholder=""
                                        type="password"
                                        autoComplete="new-password"
                                        className="inputdata"
                                        invalid={errorshown ? true : false}
                                    />
                                    <FormFeedback>{error}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter>
                            <Button id="submitbutton" type="submit" color="simple" disabled={disabled}>Login</Button>
                        </CardFooter>
                    </Card>
                    <div style={{textAlign: 'center', fontSize: '13px'}}>DispenSecur © Verinetics</div>
                </form>
            ) : (
                <Redirect to={{ pathname: '/' }} />
            )}
                </div>
            </div>
            </div>
        );
    }
}


export default LoginPage;