import React from "react";

import {
    Row,
    Col,
    FormGroup,
    Input,
    Form,
    Label ,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
} from "reactstrap";

import { states } from '../../variables/states';
import { service } from "services";

import { DropdownList } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';

import ImageUploader from 'react-images-upload';

var _self;

let default_data = {
    address: "",
    city: "",
    clinic: "",
    clinics_id: "0",
    email: "",
    first_name: "",
    id: 0,
    is_counselor: 0,
    last_name: "",
    phone: "",
    state: "",
    username: "",
    zip: "",
    password: "",
    picture: require("assets/img/user.png"),
    picture_type: 0,
    picture_name: 'user.png'
  };

export default class UsersForm extends React.Component {
    constructor(props) {
        super(props);
  
        const id = this.props.id ? this.props.id : 0;

        this.onChange = this.onChange.bind(this);

        this.state = {
          id: id,
          data: { ...default_data },
          reload: false,
          clinics: [],
        };
    }

    componentDidMount() {
        _self = this;
        service.getItems('clinics').then(
            resp => {
              if (resp.clinics) {
                resp.clinics.unshift({ "id": "0", "name": "No clinic" });
                this.setState({clinics: resp.clinics});
              }
            }
          );

        this.getData(this.state.id);
    }

    componentDidUpdate() {
        if (this.props.id !== this.state.id) {
            this.setState({id: this.props.id});
            if (this.props.action === 'edit') {
                this.getData(this.props.id);
            } else {
                this.setState({data: { ...default_data }});
            }
        }
    }

    onDrop(picture) {
        var reader = new FileReader();
        reader.readAsDataURL(picture[0]);
        reader.onload = function () {
            var d = { ..._self.state.data };
            d.picture = reader.result;
            d.picture_type = 1;
            d.picture_name = picture[0].name;

            _self.setState({ data: d });
            _self.props.setData(d);
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
    }

    onChange(d, m) {
        this.props.onChange(d, m);
        var data = {...this.state.data};
        data[m] = d;

        this.setState({ data: data });
        this.props.setData(data);
    }

    getData(id) {
        var new_data = {...default_data};

        if (id > 0) {
            service.getItems('users/' + id).then(
                resp => {
                    if (resp.users) {
                        new_data = resp.users[0];
                        new_data.password = '';
                        if (!new_data.picture) new_data.picture = default_data.picture;
                        if (!new_data.picture_type) new_data.picture_type = default_data.picture_type;
                        if (!new_data.picture_name) new_data.picture_name = default_data.picture_name;

                        this.setState({data: new_data});
                        this.props.setData(new_data);
                    }
                }
            );
        } else {
            this.setState({data: new_data});
            this.props.setData(new_data);
        }
    }

    render() {
        const { data } = this.state;

        return (
            <Form>
                <Row>
                    <Col md="4">
                        <Card className="card-user">
                            <div className="image">
                            <img
                                alt="..."
                                src={require("assets/img/patients_background.jpg")}
                            />
                            </div>
                            <CardBody>
                                <div className="author">
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <img
                                        alt="..."
                                        className="avatar border-gray"
                                        src={data.picture}
                                    />
                                    </a>
                                    <ImageUploader
                                        name="picture"
                                        withIcon={false}
                                        withLabel={false}
                                        singleImage={true}
                                        buttonText='Choose image'
                                        onChange={this.onDrop}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                        maxFileSize={5242880}
                                        className="picture_uploader inputdata"
                                    />
                                    <h5 className="title patient_name">{ data.first_name + " " + data.last_name }</h5>
                                    <p className="description">{data.email}</p>
                                </div>
                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col md="8">
                        <Card className="card-user">
                            <CardHeader>
                                <CardTitle tag="h4">User data</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="3">
                                        <FormGroup>
                                        <label>First name</label>
                                        <Input
                                            id="first_name"
                                            value={data.first_name}
                                            onChange={(d) => this.onChange(d.currentTarget.value, "first_name")}
                                            placeholder=""
                                            type="text"
                                            className="inputdata mandatory"
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                        <label>Last name</label>
                                        <Input
                                            id="last_name"
                                            value={data.last_name}
                                            onChange={(d) => this.onChange(d.currentTarget.value, "last_name")}
                                            placeholder=""
                                            type="text"
                                            className="inputdata mandatory"
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                        <label>Username</label>
                                        <Input
                                            id="username"
                                            value={data.username}
                                            onChange={(d) => this.onChange(d.currentTarget.value, "username")}
                                            autoComplete="new-password"
                                            placeholder=""
                                            type="text"
                                            className="inputdata mandatory"
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                        <label>Password</label>
                                        <Input 
                                            value={data.password}
                                            onChange={(d) => this.onChange(d.currentTarget.value, "password")}
                                            placeholder="" 
                                            autoComplete="new-password"
                                            type="password" 
                                            id="password"
                                            className="inputdata"
                                        />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="2">
                                        <FormGroup>
                                        <label>Phone number</label>
                                        <Input
                                            id="phone"
                                            value={data.phone}
                                            onChange={(d) => this.onChange(d.currentTarget.value, "phone")}
                                            placeholder=""
                                            type="text"
                                            className="inputdata mandatory"
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                        <label htmlFor="exampleInputEmail1">
                                            Email address
                                        </label>
                                        <Input 
                                            value={data.email}
                                            onChange={(d) => this.onChange(d.currentTarget.value, "email")}
                                            placeholder="" 
                                            type="email" 
                                            id="email"
                                            className="inputdata mandatory"
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                        <label>Clinic</label>
                                        <DropdownList
                                            id="clinics_id"
                                            data={this.state.clinics}
                                            valueField='id'
                                            value={data.clinics_id}
                                            onChange={(d) => this.onChange(d.id, "clinics_id")}
                                            textField='name'
                                            className="inputdata"
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup check>
                                        <Label check>
                                            <Input
                                                id="is_counselor"
                                                type="checkbox"
                                                className="inputdata"
                                                checked={ data.is_counselor === 1 ? true : false }
                                                onChange={(d) => this.onChange(d.currentTarget.checked ? 1 : 0, "is_counselor")}
                                            />
                                            Counselor?
                                        </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4">
                                        <FormGroup>
                                        <label>Address</label>
                                        <Input
                                            id="address"
                                            value={data.address}
                                            onChange={(d) => this.onChange(d.currentTarget.value, "address")}
                                            placeholder=""
                                            type="text"
                                            className="inputdata"
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                        <label>City</label>
                                        <Input
                                            id="city"
                                            value={data.city}
                                            onChange={(d) => this.onChange(d.currentTarget.value, "city")}
                                            placeholder=""
                                            type="text"
                                            className="inputdata"
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                        <label>Postal Code</label>
                                        <Input
                                            id="zip"
                                            value={data.zip}
                                            onChange={(d) => this.onChange(d.currentTarget.value, "zip")}
                                            placeholder="" 
                                            type="text"
                                            className="inputdata"
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                        <label>State</label>
                                        <DropdownList filter
                                            id="state"
                                            data={states}
                                            valueField='id'
                                            value={data.state}
                                            onChange={(d) => this.onChange(d.id, "state")}
                                            textField='name'
                                            className="inputdata"
                                        />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter></CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Form>
          );
    }
};
