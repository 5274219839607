import React from "react"
import { compose, withProps, lifecycle, withStateHandlers } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker,InfoWindow, Polyline, Circle } from "react-google-maps"

const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");

const MapWithAMarkers = compose(
  withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCN0SvPMA7jdsIgtNZbLD7EA31_qa1nVBM&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `100%` }} />,
      mapElement: <div style={{ height: `100%` }} />
  }),
  lifecycle({
    componentDidMount() {
      const refs = {};

      this.setState({
          pathCoordinates: [
              { lat: 45.8007467, lng: 15.9795423 },
              { lat: 45.8976983, lng: 16.8398712 }
          ],
          isOpen: this.props.selected ? true : false,
          infoIndex: this.props.selected,
          markers: this.props.markers,
          handleChangeMarker: this.props.handleChangeMarker,
          zoomToMarkers: map => {
              refs.map = map;
              const bounds = new window.google.maps.LatLngBounds();
              const arrow = window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW;
              if (map) {
                var center;
                map.props.children.forEach((child) => {
                  if (child !== undefined && child) {
                    if (child.type !== SearchBox && child.type !== Circle) {
                      child.forEach((innerchild) => {
                        if (innerchild.type === Marker) {
                          center = new window.google.maps.LatLng(innerchild.props.position.lat, innerchild.props.position.lng);
                          bounds.extend(new window.google.maps.LatLng(innerchild.props.position.lat, innerchild.props.position.lng));
                        }
                        if (innerchild.type === Polyline) {
                          innerchild.props.options.icons[0].icon.path = arrow;
                        }
                      })
                    }
                  }
                })

                if (!map.props.zoom) {
                  map.fitBounds(bounds);
                } else if (center) {
                  setTimeout(() => {
                    if (document.getElementById("mapcontainer")) document.getElementById("mapcontainer").style.display = '';
                    map.panTo(center);
                  }, 1000);
                }
              }
          },
          onSearchBoxMounted: ref => {
            refs.searchBox = ref;
          },
          onPlacesChanged: onChange => {
            const places = refs.searchBox.getPlaces();
            this.props.handleChangeMarker(places[0].geometry.location.lat(), places[0].geometry.location.lng());
          },
          polycoords: this.props.polycoords,
      })
    }
    ,
    componentDidUpdate() {
        if (this.props.markers !== this.state.markers) {
        this.setState({
          markers: this.props.markers,
          isOpen: this.props.selected ? true : false,
          infoIndex: this.props.selected,
          polycoords: this.props.polycoords,
          lat: this.props.lat,
          lng: this.props.lng,
          zoomToMarkers: map => {
                const bounds = new window.google.maps.LatLngBounds();
                const arrow = window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW;
                if (map) {
                  var center;
                  map.props.children.forEach((child) => {
                    if (child !== undefined && child) {
                      if (child.type !== SearchBox && child.type !== Circle) {
                        child.forEach((innerchild) => {
                          if (innerchild.type === Marker) {
                            center = new window.google.maps.LatLng(innerchild.props.position.lat, innerchild.props.position.lng);
                            bounds.extend(new window.google.maps.LatLng(innerchild.props.position.lat, innerchild.props.position.lng));
                          }
                          if (innerchild.type === Polyline) {
                            innerchild.props.options.icons[0].icon.path = arrow;
                          }
                        })
                      }
                    }
                  })

                  if (!map.props.zoom) {
                    map.fitBounds(bounds);
                  } else if (center) {
                    map.panTo(center);
                    if (document.getElementById("mapcontainer")) document.getElementById("mapcontainer").style.display = '';
  
                    setTimeout(() => {
                      if (document.getElementById("mapcontainer")) document.getElementById("mapcontainer").style.display = '';
                      map.panTo(center);
                    }, 1000);
                    }
                }
            }
        })
      }
    },
  }),
  withStateHandlers(() => ({
    isOpen: false,
    infoIndex: null
  }),
  {
    showInfo: ({ isOpen, infoIndex }) => (index, lat, lng) => ({
      isOpen: infoIndex !== index || !isOpen,
      infoIndex: index,
      lat: lat,
      lng: lng
    }),
    hideInfo: () => () => ({
      isOpen: false,
      infoIndex: null
    }),
  }),
  withScriptjs,
  withGoogleMap
)(props =>
    <GoogleMap 
        options={{ 
            disableDoubleClickZoom: props.disableDoubleClickZoom, 
            scrollwheel: false,
            streetViewControl: false, 
            disableDefaultUI: true,
            fullscreenControl: true,
            zoomControl: true,
        }} 
        //onDblClick={(event) => props.handleChangeMarker(event.latLng.lat(), event.latLng.lng())} 
        ref={props.zoomToMarkers} 
        defaultZoom={ props.zoom ? props.zoom : 5} 
        zoom={props.zoom} 
        center={{ lat: props.lat ? props.lat : 0, lng: props.lng ? props.lng : 0 }}
    >
        {Object.keys(props.markers).map((key) =>
            <Marker
                key={key}
                position={{ lat: props.markers[key].lat, lng: props.markers[key].lng }}
                onClick={() => props.showInfo(key, props.markers[key].lat, props.markers[key].lng)}
              >
                {
                (props.isOpen && props.infoIndex === key && props.markers[key].location) && 
                 <InfoWindow onCloseClick={props.handleCloseCall}>
                   <div style={{ lineHeight: '26px' }}>
                    <div><b>{props.markers[key].location}</b></div>
                    {props.markers[key].contents.map((content, ckey) =>
                      <div key={ckey}>{content.dt}, {content.type}, {content.product}</div>
                    )}
                   </div>
                 </InfoWindow>
                }
            </Marker>
        )}
        { props.autocomplete && 
          <SearchBox
            ref={props.onSearchBoxMounted}
            // bounds={props.bounds}
            style={{width: '100%'}}
            controlPosition={window.google.maps.ControlPosition.TOP_CENTER}
            onPlacesChanged={() => {
              props.onPlacesChanged(props.onChange);
            }}
            >
          <input
            type="text"
            placeholder={props.searchPlaceholder}
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `300px`,
              margin: '10px auto',
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
            }}
            />
          </SearchBox>
        }
            <Circle
              center={{
                lat: props.lat ? props.lat : 0,
                lng: props.lng ? props.lng : 0
              }}
              radius={500}
              options={{
                fillColor: "#a0a0a0",
                fillOpacity: 0.4,
                strokeColor: "#707070",
                strokeOpacity: 1,
                strokeWeight: 1
            }}
            />
    </GoogleMap>
    );

class MapComponent extends React.PureComponent {
  constructor(props) {
		super(props);

    this.markers = this.props.markers;
    this.lat = this.props.lat;
    this.lng = this.props.lng;
    this.markers = this.props.markers;
    this.zoom = this.props.zoom;
    this.connections = this.props.connections;
    this.handleChangeMarker = this.props.handleChangeMarker;
    this.autocomplete = this.props.autocomplete;
    this.disableDoubleClickZoom = this.props.disableDoubleClickZoom;
    this.selected = this.props.selected;

    this.polycoords = [];

    var last_lat = this.lat - 1.5;
    var last_lng = this.lng - 0.5;

    for (var i = 0; i < 8; i++) {
      if (i < 5) {
        last_lat += 0.5;
      } else {
        last_lat -= 0.5;
      }

      if (i < 3 || i > 6) {
        last_lng += 0.5;
      } else if (i < 7) {
        last_lng -= 0.5;
      }

      this.polycoords.push({ lat: last_lat, lng: last_lng });
    }

    const reversedCoords = this.polycoords.map( ll => {
      return { lat: ll.lng, lng: ll.lat }
    });
        
    this.state = {
      markers: this.markers,
      selected: this.selected,
      lat: this.lat,
      lng: this.lng,
      connections: this.connections,
      zoom: this.zoom,
      handleChangeMarker: this.handleChangeMarker,
      autocomplete: this.autocomplete,
      disableDoubleClickZoom: this.disableDoubleClickZoom,
      polycoords: reversedCoords,
	};
  }

  componentDidUpdate() {
    if (this.props.lat !== this.state.lat || this.props.lng !== this.state.lng) {
      this.setState({
        markers: this.props.markers,
        lat: this.props.lat,
        lng: this.props.lng,
      });  
    }
  }

  handleChangeMarkerOwn = (lat, lng) => {
    if (this.handleChangeMarker) {
      var this_markers = [];
      this_markers[0] = { lat: lat, lng: lng };
      this.setState({
        markers: this_markers,
        lat: lat,
        lng: lng,
      });
      this.handleChangeMarker(lat, lng);
    }
  }

  render() {
    return (
      <>
      { 
        this.state.lat !== 0 || this.state.lng !== 0 ? 
        <MapWithAMarkers 
          zoom={this.state.zoom} 
          autocomplete={this.state.autocomplete} 
          markers={this.state.markers} 
          disableDoubleClickZoom={this.state.disableDoubleClickZoom}
          polycoords={this.state.polycoords}
          lat={this.state.lat} 
          lng={this.state.lng} 
          selected={this.state.selected}
          handleChangeMarker={this.handleChangeMarkerOwn}
          searchPlaceholder="Search"
          connections={this.state.connections} />
        :
        <div style={{textAlign: 'center', margin: '20px'}}>No location data</div>
      }
    </>
    )
  }
}

export default MapComponent;