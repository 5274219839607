import React from "react";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
} from "reactstrap";

import { animations } from 'react-animation';

import ClinicsForm from "../../views/Clinics/Form";
import PatientsForm from "../../views/Patients/Form";
import DevicesReloadForm from "../../views/Patients/Reload";
import DevicesForm from "../../views/Devices/Form";
import UsersForm from "../../views/Users/Form";

export default class MainForm extends React.Component {
    constructor(props) {
      super(props);

      const display = this.props.display ? this.props.display : false;
      const module = this.props.module ? this.props.module : '';
      const id = this.props.id ? this.props.id : 0;
      const action = this.props.action ? this.props.action : '';
      const form = this.props.form ? this.props.form : '';
      this.onChangeButtons = this.onChangeButtons.bind(this);
  
      this.state = {
        display: display,
        module: module,
        id: id,
        action: action,
        form: form,
        b: null,
      };
    }

    componentDidUpdate() {
        var s = this.state;
        var p = this.props;

        var changed = false;
        if (p.display !== undefined && p.display !== s.display) changed = 1;
        if (p.module !== undefined && p.module !== s.module) changed = 1;
        if (p.id !== undefined && p.id !== s.id) changed = 1;
        if (p.action !== undefined && p.action !== s.action) changed = 1;
        if (p.form !== undefined && p.form !== s.form) changed = 1;

        if (changed) {
            var display = p.display !== undefined ? p.display : s.display;
            var module = p.module !== undefined ? p.module : s.module;
            var id = p.id !== undefined ? p.id : s.id;
            var action = p.action !== undefined ? p.action : s.action;
            var form = p.form !== undefined ? p.form : s.form;

            this.setState({ display: display, module: module, id: id, action: action, form: form });
        }
    }

    onChangeButtons(b) {
        this.setState({buttons: b});
    }

    render() {
        const { display, module, id, action, form } = this.state;

        var F = null;
        var Fbuttons = true;
        var Ftitle = '';

        switch (module) {
            case 'clinics':
                F = ClinicsForm;
                Ftitle = 'Edit clinic';
                break;
            case 'users':
                F = UsersForm;
                Ftitle = 'Edit user';
                break;
            case 'devices':
                F = DevicesForm;
                Ftitle = 'Edit device';
                break;
            case 'patients':
                F = PatientsForm;
                Ftitle = 'Patient overview';
                if (form === 'reload') {
                    F = DevicesReloadForm;
                    Ftitle = 'Reload device';
                    Fbuttons = false;
                }
                break;
            default:
                break;
        }

        return (
            <Card className={"card-" + module + form} style={{animation: display ? animations.popIn : '', display: display ? ''  :  'none'}}>
                <CardHeader className={"cardHeader-" + module + form}>
                    <CardTitle tag="h5">{Ftitle}</CardTitle>
                    { 
                    this.state.buttons ? 
                    this.state.buttons
                    :
                    <>
                    {
                        Fbuttons && 
                        <Button
                            className="btn-round"
                            color="primary"
                            type="button"
                            onClick={() => this.props.submitHandler()}
                        >
                            Save
                        </Button>
                    }
                        <Button
                            className="btn-round"
                            color="alert"
                            type="button"
                            onClick={() => this.props.cancelHandler()}
                        >
                            Cancel
                        </Button>
                    </>
                    }
                </CardHeader>
                <CardBody>
                    <F id={id} onChange={this.props.onChange} setData={this.props.setData} action={action} onChangeButtons={this.onChangeButtons} />
                </CardBody>
          </Card>
          );
    }
};
