const states = [
    {
       "id":"AL",
       "name":"Alabama",
       "coordinates":[
          -86.279118,
          32.361538
       ],
       "capital":"Montgomery"
    },
    {
       "id":"AK",
       "name":"Alaska",
       "coordinates":[
          -134.41974,
          58.301935
       ],
       "capital":"Juneau"
    },
    {
       "id":"AZ",
       "name":"Arizona",
       "coordinates":[
          -112.073844,
          33.448457
       ],
       "capital":"Phoenix"
    },
    {
       "id":"AR",
       "name":"Arkansas",
       "coordinates":[
          -92.331122,
          34.736009
       ],
       "capital":"Little Rock"
    },
    {
       "id":"CA",
       "name":"California",
       "coordinates":[
          -121.468926,
          38.555605
       ],
       "capital":"Sacramento"
    },
    {
       "id":"CO",
       "name":"Colorado",
       "coordinates":[
          -104.984167,
          39.7391667
       ],
       "capital":"Denver"
    },
    {
       "id":"CT",
       "name":"Connecticut",
       "coordinates":[
          -72.677,
          41.767
       ],
       "capital":"Hartford"
    },
    {
       "id":"DE",
       "name":"Delaware",
       "coordinates":[
          -75.526755,
          39.161921
       ],
       "capital":"Dover"
    },
    {
       "id":"FL",
       "name":"Florida",
       "coordinates":[
          -84.27277,
          30.4518
       ],
       "capital":"Tallahassee"
    },
    {
       "id":"GA",
       "name":"Georgia",
       "coordinates":[
          -84.39,
          33.76
       ],
       "capital":"Atlanta"
    },
    {
       "id":"HI",
       "name":"Hawaii",
       "coordinates":[
          -157.826182,
          21.30895
       ],
       "capital":"Honolulu"
    },
    {
       "id":"ID",
       "name":"Idaho",
       "coordinates":[
          -116.237651,
          43.613739
       ],
       "capital":"Boise"
    },
    {
       "id":"IL",
       "name":"Illinois",
       "coordinates":[
          -89.650373,
          39.78325
       ],
       "capital":"Springfield"
    },
    {
       "id":"IN",
       "name":"Indiana",
       "coordinates":[
          -86.147685,
          39.790942
       ],
       "capital":"Indianapolis"
    },
    {
       "id":"IA",
       "name":"Iowa",
       "coordinates":[
          -93.620866,
          41.590939
       ],
       "capital":"Des Moines"
    },
    {
       "id":"KS",
       "name":"Kansas",
       "coordinates":[
          -95.69,
          39.04
       ],
       "capital":"Topeka"
    },
    {
       "id":"KY",
       "name":"Kentucky",
       "coordinates":[
          -84.86311,
          38.197274
       ],
       "capital":"Frankfort"
    },
    {
       "id":"LA",
       "name":"Louisiana",
       "coordinates":[
          -91.140229,
          30.45809
       ],
       "capital":"Baton Rouge"
    },
    {
       "id":"ME",
       "name":"Maine",
       "coordinates":[
          -69.765261,
          44.323535
       ],
       "capital":"Augusta"
    },
    {
       "id":"MD",
       "name":"Maryland",
       "coordinates":[
          -76.501157,
          38.972945
       ],
       "capital":"Annapolis"
    },
    {
       "id":"MA",
       "name":"Massachusetts",
       "coordinates":[
          -71.0275,
          42.2352
       ],
       "capital":"Boston"
    },
    {
       "id":"MI",
       "name":"Michigan",
       "coordinates":[
          -84.5467,
          42.7335
       ],
       "capital":"Lansing"
    },
    {
       "id":"MN",
       "name":"Minnesota",
       "coordinates":[
          -93.094,
          44.95
       ],
       "capital":"Saint Paul"
    },
    {
       "id":"MS",
       "name":"Mississippi",
       "coordinates":[
          -90.207,
          32.32
       ],
       "capital":"Jackson"
    },
    {
       "id":"MO",
       "name":"Missouri",
       "coordinates":[
          -92.189283,
          38.572954
       ],
       "capital":"Jefferson City"
    },
    {
       "id":"MT",
       "name":"Montana",
       "coordinates":[
          -112.027031,
          46.595805
       ],
       "capital":"Helana"
    },
    {
       "id":"NE",
       "name":"Nebraska",
       "coordinates":[
          -96.675345,
          40.809868
       ],
       "capital":"Lincoln"
    },
    {
       "id":"NV",
       "name":"Nevada",
       "coordinates":[
          -119.753877,
          39.160949
       ],
       "capital":"Carson City"
    },
    {
       "id":"NH",
       "name":"New Hampshire",
       "coordinates":[
          -71.549127,
          43.220093
       ],
       "capital":"Concord"
    },
    {
       "id":"NJ",
       "name":"New Jersey",
       "coordinates":[
          -74.756138,
          40.221741
       ],
       "capital":"Trenton"
    },
    {
       "id":"NM",
       "name":"New Mexico",
       "coordinates":[
          -105.964575,
          35.667231
       ],
       "capital":"Santa Fe"
    },
    {
       "id":"NY",
       "name":"New York",
       "coordinates":[
          -73.781339,
          42.659829
       ],
       "capital":"Albany"
    },
    {
       "id":"NC",
       "name":"North Carolina",
       "coordinates":[
          -78.638,
          35.771
       ],
       "capital":"Raleigh"
    },
    {
       "id":"ND",
       "name":"North Dakota",
       "coordinates":[
          -100.779004,
          48.813343
       ],
       "capital":"Bismarck"
    },
    {
       "id":"OH",
       "name":"Ohio",
       "coordinates":[
          -83.000647,
          39.962245
       ],
       "capital":"Columbus"
    },
    {
       "id":"OK",
       "name":"Oklahoma",
       "coordinates":[
          -97.534994,
          35.482309
       ],
       "capital":"Oklahoma City"
    },
    {
       "id":"OR",
       "name":"Oregon",
       "coordinates":[
          -123.029159,
          44.931109
       ],
       "capital":"Salem"
    },
    {
       "id":"PA",
       "name":"Pennsylvania",
       "coordinates":[
          -76.875613,
          40.269789
       ],
       "capital":"Harrisburg"
    },
    {
       "id":"RI",
       "name":"Rhode Island",
       "coordinates":[
          -71.422132,
          41.82355
       ],
       "capital":"Providence"
    },
    {
       "id":"SC",
       "name":"South Carolina",
       "coordinates":[
          -81.035,
          34
       ],
       "capital":"Columbia"
    },
    {
       "id":"SD",
       "name":"South Dakota",
       "coordinates":[
          -100.336378,
          44.367966
       ],
       "capital":"Pierre"
    },
    {
       "id":"TN",
       "name":"Tennessee",
       "coordinates":[
          -86.784,
          36.165
       ],
       "capital":"Nashville"
    },
    {
       "id":"TX",
       "name":"Texas",
       "coordinates":[
          -97.75,
          30.266667
       ],
       "capital":"Austin"
    },
    {
       "id":"UT",
       "name":"Utah",
       "coordinates":[
          -111.892622,
          40.7547
       ],
       "capital":"Salt Lake City"
    },
    {
       "id":"VT",
       "name":"Vermont",
       "coordinates":[
          -72.57194,
          44.26639
       ],
       "capital":"Montpelier"
    },
    {
       "id":"VA",
       "name":"Virginia",
       "coordinates":[
          -77.46,
          37.54
       ],
       "capital":"Richmond"
    },
    {
       "id":"WA",
       "name":"Washington",
       "coordinates":[
          -122.893077,
          47.042418
       ],
       "capital":"Olympia"
    },
    {
       "id":"WV",
       "name":"West Virginia",
       "coordinates":[
          -81.633294,
          38.349497
       ],
       "capital":"Charleston"
    },
    {
       "id":"WI",
       "name":"Wisconsin",
       "coordinates":[
          -89.384444,
          43.074722
       ],
       "capital":"Madison"
    },
    {
       "id":"WY",
       "name":"Wyoming",
       "coordinates":[
          -104.802042,
          41.145548
       ],
       "capital":"Cheyenne"
    }
 ]

export {states};