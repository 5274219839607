/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Button,
} from "reactstrap";

import ReactTooltip from "react-tooltip";
import Loader from 'react-loader-spinner';

import { states } from '../../variables/states';
import { service } from "services";
let states_array = [];

class Users extends React.Component {
  constructor(props) {
    super(props);

    this.getData = this.getData.bind(this);

    this.state = {
        users: [],
        showForm: false,
        table: false,
    };
  }

  componentDidMount() {
    states.map((state, index) => {
      states_array[state.id] = state.name;

      return true;
    });

    this.getData();
  }

  componentDidUpdate() {
    if (this.props.reload === true) {
      this.getData();
    }
  }

  getData() {
    this.props.reloaded();

    service.getItems('users').then(
      resp => {
        if (resp.users) {
          this.setState({users: resp.users, table: true});
        }
      }
    );
  }

  render() {
    return (
      <>
      { this.state.table ? 
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Users list</CardTitle>
                  <Button
                    className="btn-round"
                    color="alert"
                    type="button"
                    onClick={() => this.props.editHandler(0)}
                  >
                    Add new user
                  </Button>
                </CardHeader>
                <CardBody>
                  <Table responsive hover>
                    <thead className="text-primary">
                      <tr>
                        <th>First and last name</th>
                        <th>Address</th>
                        <th>Clinic</th>
                        <th className="text-center">Is counselor?</th>
                        <th className="text-center actionsColumn">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.users.map((user, index) => {
                          var this_state = states_array[user.state] ? states_array[user.state] : '';
                          return (
                            <tr key={index}>
                              <td>
                                <img
                                  alt="..."
                                  className="avatar list border-gray"
                                  src={user.picture ? user.picture : require("assets/img/user.png")}
                                />
                                {user.first_name + " " + user.last_name}
                              </td>
                              <td>{user.address + " " + user.city + " " + this_state}</td>
                              <td>{user.clinic}</td>
                              <td className="text-center">{user.is_counselor === 0 ? 'No' : 'Yes'}</td>
                              <td className="text-center">
                                <i className="far fa-edit text-gray-dark actions" onClick={() => this.props.editHandler(user.id)} data-tip data-for={"edit" + index} />
                                <ReactTooltip id={"edit" + index} place="top" effect="solid">Edit user { user.first_name + " " + user.last_name }</ReactTooltip>
                                <i className="far fa-trash-alt text-gray-dark actions" onClick={() => this.props.deleteHandler(user.id, 'Deleting user ' + user.first_name + " " + user.last_name)} data-tip data-for={"delete" + index} />
                                <ReactTooltip id={"delete" + index} place="top" effect="solid">Delete user {user.first_name + " " + user.last_name}</ReactTooltip>
                              </td>
                            </tr>    
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
        :
          <Loader
            type="Rings"
            className="loader"
            height={100}
            width={100}

          />
        }
      </>
    );
  }
}

export default Users;
