/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from "reactstrap";

import { animations } from 'react-animation';

import MainForm from "../../components/Form";
import Patients from "../../views/Patients";
import Devices from "../../views/Devices";
import Clinics from "../../views/Clinics";
import Users from "../../views/Users";

import { service } from "services";

let Module = null;
let pathname = "";
let data = {};

class Lists extends React.Component {
  constructor(props) {
    super(props);

    pathname = props.location.pathname.replace("/admin/", "");

    switch (pathname) {
        case "patients":
          Module = Patients;
          break;
        case "clinics":
          Module = Clinics;
          break;
        case "devices":
          Module = Devices;
          break;
        case "users":
          Module = Users;
          break;
        default:
          break;
      }

    this.editHandler = this.editHandler.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.reloaded = this.reloaded.bind(this);
    this.onChange = this.onChange.bind(this);
    this.confirmModal = this.confirmModal.bind(this);
    this.onAlertDismiss = this.onAlertDismiss.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
        showForm: false,
        form: '',
        table: true,
        edit_id: 0,
        reload: true,
        modal: false,
        modalTitle: '',
        modalBody: '',
        modalConfirm: '',
        action: '',
        error: '',
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  escFunction(event){
    if(event.keyCode === 27 && this.state.showForm) {
      this.cancelHandler();
    }
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }

  onChange(d, m) {
    data[m] = d;
  }

  setData(d) {
    data = d;
  }

  reloaded() {
      this.setState({ reload: false });
  }

  editHandler(id, f) {
      this.setState({ table: !this.state.table, showForm: !this.state.showForm, edit_id: id, action: 'edit', reload: false, form: f ? f : '' });
  }

  cancelHandler() {
    if (this.state.error !== '') {
      this.closeModal();
    } else {
      this.setState({ modal: true, modalTitle: 'Cancel this operation?', modalBody: '', modalConfirm: 'Close', action: 'cancel', cancel: true });
    }
  }

  closeModal() {
    this.setState({ modal: false, error: '' });
  }

  submitHandler(title) {
    if (!title) title = "Save form";

    var errors = '';
    var mandatory = document.getElementsByClassName("mandatory");

    for (var i = 0; i < mandatory.length; i++) {
      var field = mandatory[i];

      var this_value = '';
      var this_id = field.id;
      var this_name = field.parentElement.getElementsByTagName("label")[0].innerText;

      if (field.value) {
        this_value = field.value;
        if (field.getAttribute("type") === "checkbox") this_value = field.checked ? 1 : 0;
      } else {
        var f = field.getElementsByClassName("rw-dropdown-list-autofill");
        if (f[0]) {
          this_id = this_id.replace("_input", "");
          this_value = data[this_id];
        }
      }

      if (this_value === '') {
        if (errors !== '') errors += ', ';
        errors += this_name;
      }
    }
  
    if (errors !== '') {
      var thisModalBody = 'Mandatory fields: ' + errors;
      this.setState({ modal: false, error: thisModalBody });
      setTimeout(this.onAlertDismiss, 3000);
    } else {
      this.setState({ modal: true, modalTitle: title, modalBody: '', modalConfirm: 'Save', action: 'save', error: '', reload: false, cancel: true });
    }
  }

  deleteHandler(id, title) {
    if (!title) title = "Delete";

    this.setState({ modal: true, modalTitle: title, modalBody: '', modalConfirm: 'Delete', action: 'delete', edit_id: id, error: '', reload: false, cancel: true });
  }

  confirmModal() {
    if (this.state.action === 'cancel') {
      this.setState({ modal: false, action: '', showForm: false, table:true, edit_id: null, reload: false });
    }

    if (this.state.action === 'errors') {
      this.closeModal();
    }

    if (this.state.action === 'delete') {
        service.deleteItem(pathname, this.state.edit_id).then(
          resp => {
              if (resp.errorCode > 0) {
                this.setState({ showForm: false, table:true, edit_id: 0, reload: true, modal: false, error: resp.errorMessage });
              } else {
                this.setState({ showForm: false, table:true, edit_id: 0, reload: true, modal: false, error: '' });
              }
          }
        ).catch(e => {
          this.setState({ showForm: false, table:true, edit_id: 0, reload: true, modal: false, error: e });
        });
      }

      if (this.state.action === 'save') {
        var fields = document.getElementsByClassName("inputdata");
        var json = {};
        for (var i = 0; i < fields.length; i++) {
            var field = fields[i];
            var id = field.getAttribute("id");

            if (field.value) {
              var value = field.value;
              if (field.getAttribute("type") === "checkbox") value = field.checked ? 1 : 0;
    
              json[id] = value;
            } else {
              var f = field.getElementsByClassName("rw-dropdown-list-autofill");
              if (f[0]) {
                id = id.replace("_input", "");
                json[id] = data[id];
              } else {
                if (field.classList.contains("fileUploader")) {
                  f = field.querySelector('input[type="file"]');
                  if (f) {
                    var name = f.name;
                    if (data[name]) {
                      json[name] = data[name];

                      var name_type = name + '_type';
                      json[name_type] = data[name_type];

                      name += '_name';
                      json[name] = data[name];
                    }
                  }
                }
              }
            }
        }

        service.insertItem(pathname, this.state.edit_id, json).then(
          resp => {
            if (resp.errorCode > 0) {
              this.setState({ modal: false, error: resp.errorMessage });
              setTimeout(this.onAlertDismiss, 3000);
            } else {
              this.setState({ showForm: false, table:true, edit_id: null, reload: true, modal: false, error: '' });
            }
          },
          error => {
            this.setState({ modal: false, error: error.message });
            setTimeout(this.onAlertDismiss, 3000);
          }
        ).catch(e => {
          this.setState({ showForm: false, table:true, edit_id: 0, reload: true, modal: false, error: e });
        }); 
      }
  }

  onAlertDismiss() {
    this.setState({ error: '' });
  }

  render() {
    var { showForm, table, edit_id, reload, modal, modalBody, modalTitle, modalConfirm, cancel, error, action, form } = this.state;

    if (modalBody === '') modalBody = 'Are you sure you want to proceed?';

    return (
      <>
        { error !== "" &&
        <Alert color="danger" isOpen={true} toggle={this.onAlertDismiss} >
          {error}
        </Alert>
        }
        <div className="content">
          <MainForm 
            display={showForm} 
            form={form} 
            submitHandler={this.submitHandler} 
            cancelHandler={this.cancelHandler} 
            module={pathname} action={action} 
            onChange={this.onChange} 
            setData={this.setData} 
            id={edit_id} 
          />
          <Row style={{animation: table ? animations.fadeIn : animations.fadeOut, display: table ? '': 'none'}}>
            <Col md="12">
              <Module 
                editHandler={this.editHandler} 
                deleteHandler={this.deleteHandler} 
                reload={reload} 
                reloaded={this.reloaded}
              />
            </Col>
          </Row>
        </div>
        <Modal isOpen={modal} className="">
            <ModalHeader toggle={this.cancelHandler}>{modalTitle}</ModalHeader>
            <ModalBody>{modalBody}</ModalBody>
            <ModalFooter>
                { cancel && <Button color="secondary" onClick={this.closeModal}>Cancel</Button>}
                {' '}
                <Button color="primary" onClick={this.confirmModal}>{modalConfirm}</Button>
            </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Lists;
