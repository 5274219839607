import React from "react";

import {
    Row,
    Col,
    FormGroup,
    Input,
    Form,
} from "reactstrap";

import { states } from 'variables/states';
import { service } from "services";

import { DropdownList } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';

let default_data = {
    name: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    zip: '',
    state: '',
    contact_first_name: '',
    contact_last_name: '',
  };

export default class ClinicsForm extends React.Component {
    constructor(props) {
        super(props);
  
        const id = this.props.id ? this.props.id : 0;

        this.onChange = this.onChange.bind(this);

        this.state = {
          id: id,
          data: { ...default_data },
          reload: false,
        };
    }

    componentDidMount() {
        this.getData(this.state.id);
    }

    componentDidUpdate() {
        if (this.props.id !== this.state.id) {
            this.setState({id: this.props.id});
            if (this.props.action === 'edit') {
                this.getData(this.props.id);
            } else {
                this.setState({data: { ...default_data }});
            }
        }
    }

    onChange(d, m) {
        this.props.onChange(d, m);
        var data = {...this.state.data};
        data[m] = d;

        this.setState({ data: data });
        this.props.setData(data);
    }

    getData(id) {
        if (id > 0) {
            service.getItems('clinics/' + id).then(
                resp => {
                    if (resp.clinics) {
                        this.setState({data: resp.clinics[0]});
                        this.props.setData(resp.clinics[0]);
                    }
                }
            );
        } else {
            this.setState({data: {...default_data}});
            this.props.setData({ ...default_data });
        }
    }

    render() {
        const { data } = this.state;

        return (
            <Form>
                <Row>
                    <Col md="5">
                        <FormGroup>
                        <label>Clinic name</label>
                        <Input
                            id="name"
                            value={data.name}
                            onChange={(d) => this.onChange(d.currentTarget.value, "name")}
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup>
                        <label>Phone number</label>
                        <Input
                            id="phone"
                            value={data.phone}
                            onChange={(d) => this.onChange(d.currentTarget.value, "phone")}
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                            Email address
                        </label>
                        <Input 
                            value={data.email}
                            onChange={(d) => this.onChange(d.currentTarget.value, "email")}
                            placeholder="" 
                            type="email" 
                            id="email"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col md="12">
                        <FormGroup>
                        <label>Address</label>
                        <Input
                            id="address"
                            value={data.address}
                            onChange={(d) => this.onChange(d.currentTarget.value, "address")}
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col md="4">
                        <FormGroup>
                        <label>City</label>
                        <Input
                            id="city"
                            value={data.city}
                            onChange={(d) => this.onChange(d.currentTarget.value, "city")}
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                        <label>Postal Code</label>
                        <Input
                            id="zip"
                            value={data.zip}
                            onChange={(d) => this.onChange(d.currentTarget.value, "zip")}
                            placeholder="" 
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                        <label>State</label>
                        <DropdownList filter
                            id="state"
                            data={states}
                            valueField='id'
                            defaultValue={data.state}
                            value={data.state}
                            onChange={(d) => this.onChange(d.id, "state")}
                            textField='name'
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col md="6">
                        <FormGroup>
                        <label>Contact First Name</label>
                        <Input
                            id="contact_first_name"
                            value={data.contact_first_name}
                            onChange={(d) => this.onChange(d.currentTarget.value, "contact_first_name")}
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                        <label>Contact Last Name</label>
                        <Input
                            id="contact_last_name"
                            value={data.contact_last_name}
                            onChange={(d) => this.onChange(d.currentTarget.value, "contact_last_name")}
                            placeholder=""
                            type="text"
                            className="inputdata mandatory"
                        />
                        </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col md="12">
                        <FormGroup>
                        <label>Notices</label>
                        <Input
                            id="notices"
                            type="textarea"
                            defaultValue=""
                            className="inputdata"
                        />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
          );
    }
};
