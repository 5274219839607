/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Pagination, PaginationItem, PaginationLink
} from "reactstrap";

import { service } from "services";

export default class PatientLogs extends React.Component {
    constructor(props) {
        super(props);
  
        const id = this.props.id ? this.props.id : 0;

        this.onChange = this.onChange.bind(this);
        this.getEvents = this.getEvents.bind(this);
        this.getStatuses = this.getStatuses.bind(this);
        this.getSensorData = this.getSensorData.bind(this);
        this.changePage = this.changePage.bind(this);

        this.state = {
            id: id,
            events: [],
            events_page: 1,
            events_total: 0,
            statuses: [],
            statuses_page: 1,
            statuses_total: 0,
            sensors: [],
            sensors_page: 1,
            sensors_total: 0,
            rowsPerPage: 20,
        }
    }

    componentDidMount() {
        this.getEvents(1);
        this.getStatuses(1);
        this.getSensorData(1);
    }

    getEvents(p) {
        service.getItems('events/all/' + this.state.id + '?page=' + p).then(
            resp => {
              if (resp.events) {
                this.setState({events: resp.events, events_page: p, events_total: resp.total});
              }
            }
        );
    }

    getStatuses(p) {
        service.getItems('statuses/' + this.state.id + '?page=' + p).then(
            resp => {
              if (resp.statuses) {
                this.setState({statuses: resp.statuses, statuses_page: p, statuses_total: resp.total});
              }
            }
        );
    }

    getSensorData(p) {
        service.getItems('sensordata/' + this.state.id + '?page=' + p).then(
            resp => {
              if (resp.sensordata) {
                this.setState({sensors: resp.sensordata, sensors_page: p, sensors_total: resp.total});
              }
            }
        );
    }

    changePage(p, w) {
        p = this.state[w + "_page"] + p;

        if (w === 'events') this.getEvents(p);
        if (w === 'statuses') this.getStatuses(p);
        if (w === 'sensors') this.getSensorData(p);
    }

    componentDidUpdate() {
        if (this.props.id !== this.state.id) {
            this.setState({id: this.props.id});
        }
    }

    onChange(d, m) {
        this.props.onChange(d, m);
        var data = {...this.state.data};
        data[m] = d;

        this.setData(data);
    }

    render() {
        const { events, events_page, events_total, statuses, statuses_page, statuses_total, sensors, sensors_page, sensors_total, rowsPerPage } = this.state;

        const events_pages = Math.ceil(events_total / rowsPerPage);
        const statuses_pages = Math.ceil(statuses_total / rowsPerPage);
        const sensors_pages = Math.ceil(sensors_total / rowsPerPage);
    
        const events_pinfo = <PaginationItem>
          <PaginationLink tag="button" style={{border: 0, color: 'black', margin: '0px 10px'}}>{(events_page-1) * rowsPerPage} - {events_page * rowsPerPage > events_total ? events_total : events_page * rowsPerPage} of {events_total}</PaginationLink>
        </PaginationItem>

        const statuses_pinfo = <PaginationItem>
        <PaginationLink tag="button" style={{border: 0, color: 'black', margin: '0px 10px'}}>{(statuses_page-1) * rowsPerPage} - {statuses_page * rowsPerPage > statuses_total ? statuses_total : statuses_page * rowsPerPage} of {statuses_total}</PaginationLink>
        </PaginationItem>

        const sensors_pinfo = <PaginationItem>
        <PaginationLink tag="button" style={{border: 0, color: 'black', margin: '0px 10px'}}>{(sensors_page-1) * rowsPerPage} - {sensors_page * rowsPerPage > sensors_total ? sensors_total : sensors_page * rowsPerPage} of {sensors_total}</PaginationLink>
        </PaginationItem>

        return (
        <>
            <Row>
            <Col md="3">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5">Events history</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table responsive className="small">
                                <thead className="text-primary">
                                    <tr>
                                        <th>Event</th>
                                        <th className="text-center">Position</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        events.length > 0 ?
                                        events.map((event, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{service.getDateAndTimeNow(event.dt) + " - " + event.event_title}</td>
                                                    <td className="text-center">{event.position ? event.position : '-'}</td>
                                                </tr>
                                            );
                                        })
                                        :
                                        <tr>
                                            <td className="text-center" colSpan={2}>No data</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            <nav style={{float: 'right'}}>
                                <Pagination>
                                <PaginationItem onClick={() => events_page > 1 ? this.changePage(-1, 'events') : null }>
                                    <PaginationLink style={{backgroundColor: events_page > 1 ? '': '#cacaca'}}>
                                    Back
                                    </PaginationLink>
                                </PaginationItem>
                                {events_pinfo}
                                <PaginationItem onClick={() => events_page < events_pages ? this.changePage(1, 'events') : null}>
                                    <PaginationLink next tag="button" style={{backgroundColor: events_page < events_pages ? '': '#cacaca'}}>
                                    Next
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem></PaginationItem>
                                </Pagination>
                            </nav>
                        </CardBody>
                        <CardFooter></CardFooter>
                    </Card>
                </Col>
                <Col md="3">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5">Status history</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table responsive className="small">
                                <thead className="text-primary">
                                    <tr>
                                        <th>Event</th>
                                        <th className="text-center">Reason</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        statuses.length > 0 ?
                                        statuses.map((status, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{service.getDateAndTimeNow(status.dt) + " - " + status.status}</td>
                                                    <td className="text-center">{status.status_reason}</td>
                                                </tr>
                                            );
                                        })
                                        :
                                        <tr>
                                            <td className="text-center" colSpan={2}>No data</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            <nav style={{float: 'right'}}>
                                <Pagination>
                                <PaginationItem onClick={() => statuses_page > 1 ? this.changePage(-1, 'statuses') : null }>
                                    <PaginationLink style={{backgroundColor: statuses_page > 1 ? '': '#cacaca'}}>
                                    Back
                                    </PaginationLink>
                                </PaginationItem>
                                {statuses_pinfo}
                                <PaginationItem onClick={() => statuses_page < statuses_pages ? this.changePage(1, 'statuses') : null}>
                                    <PaginationLink next tag="button" style={{backgroundColor: statuses_page < statuses_pages ? '': '#cacaca'}}>
                                    Next
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem></PaginationItem>
                                </Pagination>
                            </nav>
                        </CardBody>
                        <CardFooter></CardFooter>
                    </Card>
                </Col>
                <Col md="6">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5">Sensor data</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table responsive className="small">
                                <thead className="text-primary">
                                    <tr>
                                        <th>Time</th>
                                        <th className="text-center">Position</th>
                                        <th className="text-center">Temperature</th>
                                        <th className="text-center">Humidity</th>
                                        <th className="text-center">Pressure</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sensors.length > 0 ?
                                        sensors.map((sensor, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{service.getDateAndTimeNow(sensor.dt)}</td>
                                                    <td className="text-center">{sensor.lat + ";" + sensor.lng}</td>
                                                    <td className="text-center">{sensor.temperature}</td>
                                                    <td className="text-center">{sensor.humidity}</td>
                                                    <td className="text-center">{sensor.pressure}</td>
                                                </tr>
                                            );
                                        })
                                        :
                                        <tr>
                                            <td className="text-center" colSpan={5}>No data</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            <nav style={{float: 'right'}}>
                                <Pagination>
                                <PaginationItem onClick={() => sensors_page > 1 ? this.changePage(-1, 'sensors') : null }>
                                    <PaginationLink style={{backgroundColor: sensors_page > 1 ? '': '#cacaca'}}>
                                    Back
                                    </PaginationLink>
                                </PaginationItem>
                                {sensors_pinfo}
                                <PaginationItem onClick={() => sensors_page < sensors_pages ? this.changePage(1, 'sensors') : null}>
                                    <PaginationLink next tag="button" style={{backgroundColor: sensors_page < sensors_pages ? '': '#cacaca'}}>
                                    Next
                                    </PaginationLink>
                                </PaginationItem>
                                <PaginationItem></PaginationItem>
                                </Pagination>
                            </nav>
                        </CardBody>
                        <CardFooter></CardFooter>
                    </Card>
                </Col>
            </Row>
           </>
        );
    }
}