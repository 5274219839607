/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormFeedback,
  Table,
} from "reactstrap";

import { states } from 'variables/states';

import { DropdownList } from 'react-widgets';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import us from "date-fns/locale/en-US";

import Device from "components/device";
import MapComponent from "components/Map";

import ImageUploader from 'react-images-upload';

import { service } from "services";

import PatientLogs from "./Logs";

registerLocale("us", us); 

let default_data = {
    last_name: '',
    first_name: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    zip: '',
    state: '',
    dose: '',
    doses: 0,
    interval: '',
    interval_window: '',
    start_dt: '',
    device_id: 0,
    lat: 0,
    lng: 0,
    picture: require("assets/img/user.png"),
    picture_type: 0,
    picture_name: 'user.png',
    counselor: '',
    add_counselors_id: 0,
    counselors_picture: require("assets/img/user.png"),
    counselors_picture_name: 'user.png',
    unsecured: 0,
    locked: 0,
    charging: 0,
    intrusion: 0,
    other: 0,
    position: 0,
    skipped: 0,
    active_today: 0,
  };

var _self = this;
var to;

export default class PatientForm extends React.Component {
    constructor(props) {
        super(props);
  
        const id = this.props.id ? this.props.id : 0;
        this.markers = [];
        this.markers[0] = { lat: 0, lng: 0 };

        this.onChange = this.onChange.bind(this);
        this.handleChangeMarker = this.handleChangeMarker.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.handleLockDevice = this.handleLockDevice.bind(this);
        this.toggleLogs = this.toggleLogs.bind(this);

        this.state = {
            markers: this.markers,
            zoom: 15,
            display: false,
            id: id,
            data: { ...default_data },
            devices: [],
            events: [],
            counselors: [],
            logs: false,
        }
    }

    onDrop(picture) {
        var reader = new FileReader();
        reader.readAsDataURL(picture[0]);
        reader.onload = function () {
            var d = { ..._self.state.data };
            d.picture = reader.result;
            d.picture_type = 1;
            d.picture_name = picture[0].name;

            _self.setState({ data: d });
            _self.props.setData(d);
        };
        reader.onerror = function (error) {
        };
    }

    componentDidMount() {
        _self = this;
        service.getItems('devices').then(
            resp => {
              if (resp.devices) {
                  var devices = [{"id": 0, "hardware_id": "No device", "clinic": ""}]
                  devices = devices.concat(resp.devices);

                  this.setState({devices: devices});
                  this.getData(this.state.id, 0);
                  this.setMap();
              }
            }
        );

        service.getItems('users').then(
            resp => {
                if (resp.users) {
                    var counselors = [{"id" : 0, "name": ' '}];
                    resp.users.map((user) => {
                        if (user.is_counselor === 1) counselors.push({"id" : user.id, "name": user.first_name + " " + user.last_name});

                        return true;
                    });

                    this.setState({counselors: counselors});
                }
            }
        );
    }

    componentDidUpdate() {
        if (this.props.id !== this.state.id) {
            this.setState({id: this.props.id, logs: false, events: []});
            
            if (this.props.action === 'edit') {
                this.getData(this.props.id, 0);
            } else {
                this.setData({...default_data});
            }

            this.setMap();
        }
    }

    componentWillUnmount() {
        clearTimeout(to);
    }

    setMap() {
        if (document.getElementById("mapcontainer")) {
            document.getElementById("mapcontainer").style.display = 'none';
            setTimeout(() => {
                if (document.getElementById("mapcontainer")) document.getElementById("mapcontainer").style.display = '';
            }, 1000);
        }
    }

    onChange(d, m) {
        this.props.onChange(d, m);
        var data = {...this.state.data};
        data[m] = d;

        this.setData(data);
    }

    handleLockDevice() {
        var path = this.state.data.locked === 1 ? 'unlock' : 'lock';

        service.insertItem('device/' + path, this.state.data.device_id).then(
            resp => {
                if (resp.errorCode > 0) {
                    alert(resp.errorMessage);
                } else {
                    this.getData(this.state.data.id, 0);
                }
            }
        ).catch(e => {
            alert(e);
        });
    }

    handleRefillDevice() {
        service.insertItem('device/refill', this.state.data.device_id).then(
            resp => {
                if (resp.errorCode > 0) {
                    alert(resp.errorMessage);
                } else {
                    this.getData(this.state.data.id, 0);
                }
            }
        ).catch(e => {
            alert(e);
        });
    }

    addCounselor() {
        const { add_counselors_id } = this.state.data;

        var d = { "counselors_id": add_counselors_id };

        service.insertItem('patients/counselor', this.state.data.id, d).then(
            resp => {
                if (resp.errorCode > 0) {
                    alert(resp.errorMessage);
                } else {
                    this.getData(this.state.data.id, 0);
                }
            }
        ).catch(e => {
            alert(e);
        });
    }

    getData(id, u) {
        var new_data = {...default_data};

        if (to) clearTimeout(to);

        if (id > 0) {
            service.getItems('patients/' + id).then(
                resp => {
                    if (resp.patients) {
                        if (u === 1) {
                            new_data = this.state.data;
                            var loaded_data = resp.patients[0];

                            new_data.active_today = loaded_data.active_today;
                            new_data.skipped = loaded_data.skipped;
                            new_data.unsecured = loaded_data.unsecured;
                            new_data.locked = loaded_data.locked;
                            new_data.charging = loaded_data.charging;
                            new_data.stable = loaded_data.stable;
                            new_data.intrusion = loaded_data.intrusion;
                            new_data.other = loaded_data.other;
                            new_data.last_dt = loaded_data.last_dt
                            new_data.position = loaded_data.position;
                            new_data.positions = loaded_data.positions;
                        } else {
                            new_data = resp.patients[0];
                        }

                        new_data.lat = new_data.lat ? parseFloat(new_data.lat): 0;
                        new_data.lng = new_data.lng ? parseFloat(new_data.lng): 0;
                        if (!new_data.picture) new_data.picture = default_data.picture;
                        if (!new_data.picture_type) new_data.picture_type = default_data.picture_type;
                        if (!new_data.picture_name) new_data.picture_name = default_data.picture_name;
                        if (!new_data.counselors_picture) new_data.counselors_picture = default_data.counselors_picture;

                        this.setData(new_data);

                        service.getItems('events/' + id).then(
                            resp => {
                                if (resp.events) {
                                    this.setState({events: resp.events});
                                    to = setTimeout(() => this.getData(id, 1), 5000);
                                }
                            }
                        );
                    }
                }
            );
        } else {
            this.setData(new_data);
        }
    }

    setData(d) {
        var markers = [{lat: d.lat, lng: d.lng}];

        this.setState({ data: d, markers: markers });
        this.props.setData(d);
    }

    handleChangeMarker(lat, lng) {
        var d = {...this.state.data};
        d.lat = lat;
        d.lng = lng;

        this.setState({data: d, markers: [{lat: lat, lng: lng}]});
    }

    toggleLogs() {
        var logs = !this.state.logs;

        var button = null;

        if (logs) {
            button = <Button
                className="btn-round"
                color="alert"
                type="button"
                onClick={() => this.toggleLogs()}
                >
                    Back
            </Button>
        }

        this.props.onChangeButtons(button);

        this.setState({logs: logs});
    }

    render() {
        const { data, events, id } = this.state;

        let deviceGroupHeading = ({ item }) => (
            <span>{item !== '' ? item + ' clinic': ''}</span>
          );

        return (
        <>
            {this.state.logs ? 
            <PatientLogs id={id} />
            :
            <Form>
            <Row>
                <Col md="4">
                    <Card className="card-user">
                        <div className="image">
                        <img
                            alt="..."
                            src={require("assets/img/patients_background.jpg")}
                        />
                        </div>
                        <CardBody>
                            <div className="author">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                <img
                                    alt="..."
                                    className="avatar border-gray"
                                    src={data.picture}
                                />
                                </a>
                                <ImageUploader
                                    name="picture"
                                    withIcon={false}
                                    withLabel={false}
                                    singleImage={true}
                                    buttonText='Choose image'
                                    onChange={this.onDrop}
                                    imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                    maxFileSize={5242880}
                                    className="picture_uploader inputdata"
                                />
                                <h5 className="title patient_name">{ data.first_name + " " + data.last_name }</h5>
                                <p className="description">{data.email}</p>
                                <h6>
                                    {data.remaining} <small>Doses remaining</small>
                                </h6>
                            </div>
                        </CardBody>
                    <CardFooter>
                    <hr />
                    <div className="button-container">
                        <Row>
                            <Col className="ml-auto" lg="3" md="6" xs="6">
                                <h6>
                                    <div style={{backgroundColor: data.active_today === 1 ? '#4DAC26' : 'red', height: 15, width: 15, borderRadius: '50%', margin: '5px auto 10px'}}></div>
                                    <small>Active today</small>
                                </h6>
                            </Col>
                            <Col className="ml-auto mr-auto" lg="3" md="6" xs="6">
                                <h6>
                                    <div style={{backgroundColor: data.skipped === 0 ? '#4DAC26' : 'red', height: 15, width: 15, borderRadius: '50%', margin: '5px auto 10px'}}></div>
                                    <small>Skipped dosing</small>
                                </h6>
                            </Col>
                            <Col className="mr-auto" lg="3">
                                <h6>
                                    <div style={{backgroundColor: data.unsecured === 0 ? '#4DAC26' : 'red', height: 15, width: 15, borderRadius: '50%', margin: '5px auto 10px'}}></div>
                                    <small>Unsecured device</small>
                                </h6>
                            </Col>
                            <Col className="mr-auto" lg="3">
                                <h6>
                                    <div style={{backgroundColor: data.locked === 0 ? '#4DAC26' : 'red', height: 15, width: 15, borderRadius: '50%', margin: '5px auto 10px'}}></div>
                                    <small>Device locked</small>
                                </h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ml-auto" lg="3" md="6" xs="6">
                                <h6>
                                    <div style={{backgroundColor: data.charging === 1 ? '#4DAC26' : 'red', height: 15, width: 15, borderRadius: '50%', margin: '5px auto 10px'}}></div>
                                    <small>Charging</small>
                                </h6>
                            </Col>
                            <Col className="ml-auto mr-auto" lg="3" md="6" xs="6">
                                <h6>
                                    <div style={{backgroundColor: data.stable === 1 ? '#4DAC26' : 'red', height: 15, width: 15, borderRadius: '50%', margin: '5px auto 10px'}}></div>
                                    <small>Stable location</small>
                                </h6>
                            </Col>
                            <Col className="mr-auto" lg="3">
                                <h6>
                                    <div style={{backgroundColor: data.intrusion === 0 ? '#4DAC26' : 'red', height: 15, width: 15, borderRadius: '50%', margin: '5px auto 10px'}}></div>
                                    <small>Intrusion Detection</small>
                                </h6>
                            </Col>
                            <Col className="mr-auto" lg="3">
                                <h6>
                                    <div style={{backgroundColor: data.other === 1 ? '#4DAC26' : 'red', height: 15, width: 15, borderRadius: '50%', margin: '5px auto 10px'}}></div>
                                    <small>Other</small>
                                </h6>
                            </Col>
                        </Row>
                    </div>
                    </CardFooter>
                </Card>
                <Card className="card-user">
                    <CardHeader>
                    <CardTitle tag="h5">Patient info</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                        <Col md="6">
                            <FormGroup>
                            <label>First Name</label>
                            <Input
                                value={data.first_name}
                                id="first_name"
                                placeholder=""
                                type="text"
                                onChange={(d) => this.onChange(d.currentTarget.value, "first_name")}
                                className="inputdata mandatory"
                             />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                            <label>Last Name</label>
                            <Input
                                value={data.last_name}
                                onChange={(d) => this.onChange(d.currentTarget.value, "last_name")}
                                id="last_name"
                                className="inputdata mandatory"
                                placeholder=""
                                type="text"
                            />
                            </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                        <Col md="6">
                            <FormGroup>
                            <label>Phone</label>
                            <Input
                                value={data.phone}
                                id="phone"
                                onChange={(d) => this.onChange(d.currentTarget.value, "phone")}
                                placeholder=""
                                className="inputdata mandatory"
                                type="text"
                            />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                            <label htmlFor="exampleInputEmail1">
                                Email address
                            </label>
                            <Input 
                                placeholder="" 
                                id="email"
                                onChange={(d) => this.onChange(d.currentTarget.value, "email")}
                                type="email" 
                                value={data.email}
                                className="inputdata mandatory"
                            />
                            </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                        <Col md="12">
                            <FormGroup>
                            <label>Address</label>
                            <Input
                                value={data.address}
                                id="address"
                                onChange={(d) => this.onChange(d.currentTarget.value, "address")}
                                placeholder=""
                                className="inputdata mandatory"
                                type="text"
                            />
                            </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                        <Col md="4">
                            <FormGroup>
                            <label>City</label>
                            <Input
                                value={data.city}
                                id="city"
                                onChange={(d) => this.onChange(d.currentTarget.value, "city")}
                                placeholder=""
                                className="inputdata mandatory"
                                type="text"
                            />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                            <label>State</label>
                            <DropdownList filter
                                id="state"
                                data={states}
                                valueField='id'
                                defaultValue={data.state}
                                value={data.state}
                                onChange={(d) => this.onChange(d.id, "state")}
                                textField='name'
                                className="inputdata mandatory"
                            />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                            <label>Postal Code</label>
                            <Input 
                                placeholder="" 
                                type="number" 
                                id="zip"
                                onChange={(d) => this.onChange(d.currentTarget.value, "zip")}
                                value={data.zip} 
                                className="inputdata"
                            />
                            </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                        <Col md="12">
                            <FormGroup>
                            <label>Notices</label>
                            <Input
                                type="textarea"
                                defaultValue=""
                            />
                            </FormGroup>
                        </Col>
                        </Row>
                    </CardBody>
                </Card>
                </Col>
                <Col md="4">
                <Card className="card-user">
                <CardHeader>
                    <CardTitle tag="h5">Device overview</CardTitle>
                </CardHeader>
                <CardBody>
                    <Device 
                        device_id={data.device_id}
                        dispenserSize="350" 
                        bottleSize="50" 
                        dispenserVoid="150" 
                        loadedBottles={data.doses} 
                        maxBottles="15"
                        lineWidth="2"
                        backgroundColor="white"
                        dispenserBackgroundColor="#fafafa"
                        lineColor="#e0e0e0"
                        position={data.position}
                        positions={data.positions}
                        commands={false}
                    />
                    <Row>
                        <Col md="12">
                            <FormGroup>
                            <label>Device</label>
                            <DropdownList
                                id="device_id"
                                data={this.state.devices}
                                valueField='id'
                                defaultValue={data.device_id}
                                value={data.device_id}
                                onChange={(d) => this.onChange(d.id, "device_id")}
                                textField='hardware_id'
                                groupBy='clinic'
                                groupComponent={deviceGroupHeading}
                                className="inputdata"
                                disabled={[]}
                            />
                            <FormFeedback>Device is mandatory!</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                            <label>Start</label>
                            <DatePicker
                                locale="us"
                                id="start_dt"
                                disabledKeyboardNavigation
                                dateFormat="M/d/yyyy HH:mm"
                                timeFormat="HH:mm"
                                onChange={(d) => this.onChange(d, "start_dt")}
                                className="form-control inputdata"
                                highlightDates={[new Date()]}
                                showTimeInput={true}
                                selected={data.start_dt ? new Date(data.start_dt) : ''}
                                timeInputLabel="Time:"
                            />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                            <label>Last dose</label>
                            <Input
                                defaultValue={data.last_dt}
                                id="last_dt"
                                disabled={true}
                                type="text"
                            />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                    <Col md="3">
                            <FormGroup>
                            <label>Doses</label>
                            <Input
                                value={data.doses}
                                id="doses"
                                placeholder=""
                                className="inputdata"
                                type="number"
                                onChange={(d) => this.onChange(d.currentTarget.value, "doses")}
                            />
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                            <label>Dosage (ml)</label>
                            <Input
                                value={data.dose}
                                id="dose"
                                placeholder=""
                                className="inputdata"
                                type="number"
                                onChange={(d) => this.onChange(d.currentTarget.value, "dose")}
                            />
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                            <label>Interval (h)</label>
                            <Input
                                value={data.interval}
                                id="interval"
                                placeholder=""
                                className="inputdata"
                                onChange={(d) => this.onChange(d.currentTarget.value, "interval")}
                                type="number"
                            />
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                            <label>Window (m)</label>
                            <Input
                                value={data.interval_window}
                                id="interval_window"
                                placeholder=""
                                className="inputdata"
                                onChange={(d) => this.onChange(d.currentTarget.value, "interval_window")}
                                type="number"
                            />
                            </FormGroup>
                        </Col>
                    </Row>
                    </CardBody>
                    <CardFooter>
                    <hr />
                    <Row>
                        { this.state.id > 0 &&
                        <Col md="12">
                            <span style={{fontSize: '20px', lineHeight: '40px'}}>Device is { data.locked === 1 ? 'locked' : 'unlocked' }</span>
                            <Button
                                className="btn-round"
                                style={{float: 'right'}}
                                color="primary"
                                type="button"
                                onClick={() => this.handleLockDevice()}
                            >
                                {data.locked === 1 ? 'Unlock' : 'Lock'}
                            </Button>
                            <Button
                                className="btn-round"
                                style={{float: 'right', marginRight: '10px'}}
                                color="primary"
                                type="button"
                                onClick={() => this.handleRefillDevice()}
                            >
                                Refill
                            </Button>
                        </Col>
                        }
                    </Row>
                    </CardFooter>
                </Card>
                { this.state.id > 0 &&
                <Card>
                    <CardHeader>
                    <CardTitle tag="h4">Counselor</CardTitle>
                    </CardHeader>
                    <CardBody>
                    <ul className="list-unstyled team-members">
                        <li>
                            <Row>
                                {data.counselor !== '' ?
                                <>
                                <Col md="2" xs="2">
                                    <div className="avatar">
                                        <img
                                            alt="..."
                                            className="img-circle img-no-padding img-responsive avatar border-gray"
                                            src={data.counselors_picture}
                                        />
                                    </div>
                                </Col>
                                <Col md="7" xs="7">
                                    {data.counselor} <br />
                                    <span className="text-muted">
                                        <small>Offline</small>
                                    </span>
                                    </Col>
                                    <Col className="text-right" md="3" xs="3">
                                    <Button
                                        className="btn-round btn-icon"
                                        color="success"
                                        outline
                                        size="sm"
                                    >
                                        <i className="fa fa-envelope" />
                                    </Button>
                                </Col>
                                </>
                                : 
                                <Col style={{textAlign: 'center'}}>
                                    No counselors assigned
                                </Col>
                                }
                            </Row>
                        </li>
                        <li><hr /></li>
                        <li>
                            <Row>
                                <Col md="1"></Col>
                                <Col md="8">
                                    <FormGroup>
                                    <label>Assign counselor</label>
                                    <DropdownList
                                        id="add_counselors_id"
                                        data={this.state.counselors}
                                        valueField='id'
                                        defaultValue={data.add_counselors_id}
                                        value={data.add_counselors_id}
                                        onChange={(d) => this.onChange(d.id, "add_counselors_id")}
                                        textField='name'
                                        className="inputdata"
                                    />
                                    <FormFeedback>Device is mandatory!</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <Button
                                        className="btn-round"
                                        color="primary"
                                        type="button"
                                        style={{margin: '25px 0 0 0'}}
                                        onClick={() => this.addCounselor()}
                                    >
                                        Assign
                                    </Button>
                                </Col>
                            </Row>
                        </li>
                    </ul>
                    </CardBody>
                </Card>
                }
                </Col>
                <Col md="4">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5">Device location</CardTitle>
                        </CardHeader>
                        <CardBody style={{height: '420px'}}>
                            <div id="mapcontainer" style={{position: 'relative', height: '400px' }}>
                                {data.lat !== 0 || data.lng !== 0 ?
                                <MapComponent 
                                    autocomplete={false} 
                                    disableDoubleClickZoom={false} 
                                    connections={[]} 
                                    markers={this.state.markers} 
                                    lat={data.lat} 
                                    lng={data.lng} 
                                    handleChangeMarker={(lat, lng) => this.handleChangeMarker(lat, lng)} 
                                    zoom={this.state.zoom}
                                />
                                :
                                <div style={{textAlign: 'center', margin: '20px'}}>No location data</div>
                                }
                            </div>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Event history</CardTitle>
                            <Button
                                className="btn-round"
                                style={{float: 'right'}}
                                color="primary"
                                type="button"
                                onClick={() => this.toggleLogs()}
                            >
                                Logs
                            </Button>
                        </CardHeader>
                        <CardBody className="device_reload_inventory" style={{maxHeight: '400px', overflow: 'auto'}}>
                            <Table responsive>
                                <thead className="text-primary">
                                    <tr>
                                        <th>Event</th>
                                        <th className="text-center">Event time</th>
                                        <th className="text-center">Position</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        events.length > 0 ?
                                        events.map((event, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{event.event_title}</td>
                                                    <td className="text-center">{service.getDateAndTimeNow(event.dt)}</td>
                                                    <td className="text-center">{event.position ? event.position : '-'}</td>
                                                </tr>
                                            );
                                        })
                                        :
                                        <tr>
                                            <td className="text-center" colSpan={3}>No data</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                        <CardFooter></CardFooter>
                    </Card>
                </Col>
            </Row>
            </Form>
            }
           </>
        );
    }
}