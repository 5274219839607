/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Pagination, PaginationItem, PaginationLink,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";

import ReactTooltip from "react-tooltip";

import { animations } from 'react-animation';
import Loader from 'react-loader-spinner';

import { service } from "services";

class Audit extends React.Component {
  constructor(props) {
    super(props);

    this.changePage = this.changePage.bind(this);
    this.getAudit = this.getAudit.bind(this);
    this.showData = this.showData.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      audit: [],
      total: 0,
      page: 1,
      rowsPerPage: 10,
      table: false,
      modal: false,
      modalTitle: '',
      modalBody: '',
    };
  }

  componentDidMount() {
    this.getAudit(this.state.page);
  }

  getAudit(p) {
    service.getItems('audit?page=' + p).then(
      resp => {
        if (resp.audit) {
          this.setState({audit: resp.audit, total: resp.total, table: true, page: p});
        }
      }
    );
  }

  showData(index) {
    const { audit } = this.state;

    var this_audit = audit[index];

    var this_data = '';
    if (this_audit.data !== '') {
      this_data = "Request:" + "\n" + JSON.stringify(JSON.parse(service.b64DecodeUnicode(this_audit.data)), null, 2);;
    }

    if (this_audit.response !== '') {
      if (this_data !== '') this_data += "\n\n";
      this_data += "Response:" + "\n" + JSON.stringify(JSON.parse(service.b64DecodeUnicode(this_audit.response)), null, 2);;
    }

    var modalTitle = this_audit.dt + " - " + this_audit.message;
    var modalBody = this_data;
    
    this.setState({modal: true, modalTitle: modalTitle, modalBody: modalBody});
  }

  closeModal() {
    this.setState({modal: false, modalTitle: '', modalBody: ''});
  }

  changePage(p) {
    this.setState({table: false});

    p = this.state.page + p;
    this.getAudit(p);
  }

  render() {
    const { page, rowsPerPage, audit, table, total, modal, modalTitle, modalBody } = this.state;
    const pages = Math.ceil(total / rowsPerPage);

    const pinfo = <PaginationItem>
      <PaginationLink tag="button" style={{border: 0, color: 'black', margin: '0px 10px'}}>{(page-1) * rowsPerPage} - {page * rowsPerPage > total ? total : page * rowsPerPage} of {total}</PaginationLink>
    </PaginationItem>

    return (

      <>
        <div className="content">
          <Row style={{animation: table ? animations.fadeIn : animations.fadeOut }}>
            <Col md="12">
            { table ? 
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Audit trail</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive hover>
                    <thead className="text-primary">
                      <tr>
                        <th>Patient</th>
                        <th>Date and time</th>
                        <th>Message</th>
                        <th>Event</th>
                        <th>Position</th>
                        <th style={{width: '100px'}}>Data</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        audit.map((audit, index) => {
                          return (
                            <tr key={index}>
                              <td>{audit.name}</td>
                              <td>{audit.dt}</td>
                              <td>{audit.message}</td>
                              <td>{audit.event_title}</td>
                              <td>{audit.position}</td>
                              <td>
                                { (audit.data !== '' || audit.response !== '') &&
                                  <>
                                    <i className="far fa-eye text-gray-dark actions" onClick={() => this.showData(index)} data-tip data-for={"view" + index} />
                                    <ReactTooltip id={"view" + index} place="top" effect="solid">Show data</ReactTooltip>
                                  </>
                                }
                              </td>
                            </tr>    
                          )
                        })
                      }
                    </tbody>
                  </Table>
                  <nav style={{float: 'right'}}>
                    <Pagination>
                      <PaginationItem onClick={() => page > 1 ? this.changePage(-1) : null }>
                        <PaginationLink style={{backgroundColor: page > 1 ? '': '#cacaca'}}>
                          Back
                        </PaginationLink>
                      </PaginationItem>
                      {pinfo}
                      <PaginationItem onClick={() => page < pages ? this.changePage(1) : null}>
                        <PaginationLink next tag="button" style={{backgroundColor: page < pages ? '': '#cacaca'}}>
                          Next
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem></PaginationItem>
                    </Pagination>
                  </nav>
                </CardBody>
              </Card>
              :
                <Loader
                  type="Rings"
                  className="loader"
                  height={100}
                  width={100}

                />
              }
            </Col>
          </Row>
        </div>
        <Modal isOpen={modal} className="wide">
            <ModalHeader toggle={this.closeModal}>{modalTitle}</ModalHeader>
            <ModalBody><pre>{modalBody}</pre></ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.closeModal}>Close</Button>
            </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Audit;
