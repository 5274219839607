/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import Loader from 'react-loader-spinner';

import routes from "routes.js";

import '../assets/css/style.css';

import { service } from '../services';

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.getSession = this.getSession.bind(this);

    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      session: 'waiting',
      name: '',
      last_name: '',
      clinic: '',
    };
    this.mainPanel = React.createRef();
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1 && this.mainPanel.current) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    this.getSession();
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1 && ps) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
    if (this.props !== e) {
      this.getSession();
    }
  }
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };

  getSession() {
    service.getItems('session').then(
      resp => {
        if (resp.session) {
          this.setState({ "session": "ok", "name": resp.user.name, "last_name": resp.user.last_name, "clinic": resp.user.clinic });
        } else {
          this.setState({ "session": "logout" });
        }
      }
    );
  }

  render() {
    return (
      this.state.session === "ok" ? (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref={this.mainPanel}>
          <DemoNavbar {...this.props} name={this.state.name} last_name={this.state.last_name} clinic={this.state.clinic} />
          <Switch>
            {routes.map((prop, key) => {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            })}
          </Switch>
          <Footer fluid />
        </div>
      </div>
      ):(
        this.state.session === "waiting" ? 
        <Loader
          type="Rings"
          className="loader"
          height={100}
          width={100}

        />
        :
        <Redirect to="/login" />
      )
    );
  }
}

export default Dashboard;
