/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Button,
} from "reactstrap";

import ReactTooltip from "react-tooltip";
import Loader from 'react-loader-spinner';

import { states } from '../../variables/states';
import { service } from "services";
let states_array = [];

class Clinics extends React.Component {
  constructor(props) {
    super(props);

    this.getData = this.getData.bind(this);

    this.state = {
        clinics: [],
        showForm: false,
        table: false,
    };
  }

  componentDidMount() {
    states.map((state, index) => {
      states_array[state.id] = state.name;

      return true;
    });

    this.getData();
  }

  componentDidUpdate() {
    if (this.props.reload === true) {
      this.getData();
    }
  }

  getData() {
    this.props.reloaded();

    service.getItems('clinics').then(
      resp => {
        if (resp.clinics) {
          this.setState({clinics: resp.clinics, table: true});
        }
      }
    );
  }

  render() {
    return (
      <>
      { this.state.table ? 
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Clinics list</CardTitle>
                  <Button
                    className="btn-round"
                    color="alert"
                    type="button"
                    onClick={() => this.props.editHandler(0)}
                  >
                    Add new clinic
                  </Button>
                </CardHeader>
                <CardBody>
                  <Table responsive hover>
                    <thead className="text-primary">
                      <tr>
                        <th>Clinic</th>
                        <th>Location</th>
                        <th className="text-center">Patients</th>
                        <th className="text-center">Devices</th>
                        <th className="text-center">Counselors</th>
                        <th className="text-center actionsColumn">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.clinics.map((clinic, index) => {
                          var this_state = states_array[clinic.state] ? states_array[clinic.state] : '';
                          return (
                            <tr key={index}>
                              <td>{clinic.name}</td>
                              <td>{clinic.address + " " + clinic.city + " " + this_state}</td>
                              <td className="text-center">{clinic.patients}</td>
                              <td className="text-center">{clinic.devices}</td>
                              <td className="text-center">{clinic.counselors}</td>
                              <td className="text-center">
                                <i className="far fa-edit text-gray-dark actions" onClick={() => this.props.editHandler(clinic.id)} data-tip data-for={"edit" + index} />
                                <ReactTooltip id={"edit" + index} place="top" effect="solid">Edit {clinic.name} clinic</ReactTooltip>
                                <i className="far fa-trash-alt text-gray-dark actions" onClick={() => this.props.deleteHandler(clinic.id, 'Deleting ' + clinic.name + ' clinic')} data-tip data-for={"delete" + index} />
                                <ReactTooltip id={"delete" + index} place="top" effect="solid">Delete {clinic.name} clinic</ReactTooltip>
                              </td>
                            </tr>    
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
      :
        <Loader
          type="Rings"
          className="loader"
          height={100}
          width={100}
 
        />
      }
      </>
    );
  }
}

export default Clinics;
