import React from "react";

import {
    Row,
    Col,
    FormGroup,
    Input,
    Form,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Table,
} from "reactstrap";

import Device from 'components/device';
import 'react-widgets/dist/css/react-widgets.css';

import { states } from 'variables/states';
import { service } from "services";
let states_array = [];

let default_data = {
    hardware_id: '',
    clinics_id: '',
    patients_id: '',
  };

export default class DevicesReloadForm extends React.Component {
    constructor(props) {
        super(props);
  
        const id = this.props.id ? this.props.id : 0;
        this.onChange = this.onChange.bind(this);
        this.onDeviceChange = this.onDeviceChange.bind(this);
        
        this.state = {
          id: id,
          data: default_data,
          clinics: [],
          patients: [],
          log: [],
          inventory: [],
        };
    }

    componentDidMount() {
        states.map((state, index) => {
            states_array[state.id] = state.name;
      
            return true;
          });

        this.getData(this.state.id);
    }

    componentDidUpdate() {
        if (this.props.id !== this.state.id) {
            this.setState({id: this.props.id});
            this.getData(this.props.id);
        }
    }

    getData(id) {
        if (id > 0) {
            service.getItems('devices/' + id).then(
                resp => {
                    if (resp.devices[0]) {
                        this.setState({data: resp.devices[0]});
                        this.props.setData(resp.devices[0]);
                    } else {
                        this.setState({data: { ...default_data }});
                        this.props.setData({ ...default_data });
                    }
                }
            );
        } else {
            this.setState({data: { ...default_data }});
            this.props.setData({ ...default_data });
        }
    }
    
    onChange(d, m) {
        this.props.onChange(d, m);
        var data = {...this.state.data};
        data[m] = d;
        this.setState({ data: data });
    }

    onDeviceChange(d) {
        this.setState({ log: d.log, inventory: d.inventory });
    }
    
    render() {
        const { data, id, log, inventory } = this.state;

        var clinic_location = '';
        if (data) {
            clinic_location = data.clinic_address;
            if (clinic_location !== '') clinic_location += ', ';
            clinic_location += data.clinic_city;
            if (clinic_location !== '') clinic_location += ', ';
            clinic_location += states_array[data.clinic_state] ? states_array[data.clinic_state] : '';
        }

        return (
                <Form>
                    <Row>
                    <Col md="3">
                    <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Patient details</CardTitle>
                            </CardHeader>
                            <CardBody>
                            <FormGroup>
                                    <label>Hardware Id</label>
                                    <Input value={data.hardware_id} readOnly={true} />
                                </FormGroup>
                                <FormGroup>
                                    <label>Patient name</label>
                                    <Input value={data.patient} readOnly={true} />
                                </FormGroup>
                                <FormGroup>
                                    <label>OTP Clinic</label>
                                    <Input value={data.clinic} readOnly={true} />
                                </FormGroup>
                                <FormGroup>
                                    <label>Location</label>
                                    <Input value={clinic_location} readOnly={true} />
                                </FormGroup>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Prescription details</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <FormGroup>
                                    <label>Doses</label>
                                    <Input value={data.doses} readOnly={true} />
                                </FormGroup>
                                <FormGroup>
                                    <label>Dosage</label>
                                    <Input value={data.dose + " ml"} readOnly={true} />
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="5">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Device details</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Device 
                                    device_id={id}
                                    dispenserSize="450" 
                                    bottleSize="70" 
                                    dispenserVoid="150" 
                                    loadedBottles={data.doses} 
                                    maxBottles="15"
                                    position={data.position}
                                    positions={data.positions}
                                    lineWidth="2"
                                    backgroundColor="white"
                                    dispenserBackgroundColor="#fafafa"
                                    lineColor="#e0e0e0"
                                    onChange={this.onDeviceChange}
                                    commands={true}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4"></CardTitle>
                            </CardHeader>
                            <CardBody className="device_reload_inventory">
                                <h6>Doses Removed/Added</h6>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th className="text-center">Id</th>
                                            <th className="text-center">Position</th>
                                            <th className="text-center">Time</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            log.length > 0 ?
                                                log.map((this_log, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="text-center">{this_log.bottle_id}</td>
                                                            <td className="text-center">{this_log.position}</td>
                                                            <td className="text-center">{this_log.dt}</td>
                                                            <td className="text-center">{this_log.action}</td>
                                                        </tr>
                                                    );
                                                })
                                            :
                                            <tr>
                                                <td className="text-center" colSpan={4}>No data</td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                                <h6>Inventory Report</h6>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th className="text-center">Dose Identifier</th>
                                            <th className="text-center">Dose Position</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            inventory.length > 0 ?
                                            inventory.map((this_inventory, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="text-center">{this_inventory.bottle_id}</td>
                                                            <td className="text-center">{this_inventory.position}</td>
                                                        </tr>
                                                    );
                                                })
                                            :
                                            <tr>
                                                <td className="text-center" colSpan={2}>No data</td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                    </Row>
                </Form>
          );
    }
};
