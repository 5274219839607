/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
import {
  dashboard24HoursPerformanceChart,
} from "variables/charts.js";

import { states } from '../../variables/states';

import { VectorMap } from 'react-jvectormap';
import '../../assets/css/vectormap.css';

import { service } from "services";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        stats: {
          patients: '',
          clinics: '',
          devices: '',
          counselors: '',
        },
    };
  }

  componentDidMount() {
    service.getItems('stats').then(
      resp => {
        if (resp.stats) {
          this.setState({stats: resp.stats});
        }
      }
    );
  }

  render() {
    const { stats } = this.state;

    var states_devices_json = {};

    var states_array = {};
    states.map((state, index) => {
      states_array[state.id] = { latLng: state.coordinates, name: state.name }
      return true;
    });

    var states_used = [];
    
    if (stats.states_devices) {
      stats.states_devices.map((state_device, index) => {
        var lng = states_array[state_device.state].latLng[1];
        var lat = states_array[state_device.state].latLng[0];

        if (states_used.includes(state_device.state)) {
          lng += 1;
        } else {
          states_used.push(state_device.state);
        }

        var fill = state_device.active_today === 1 ? '#4DAC26' : '#ef8157';
        var active = state_device.active_today === 1 ? 'active' : 'inactive';
        
        states_devices_json['US' + index] = {
          latLng: [lng, lat], 
          name: state_device.count + (state_device.count > 1 ? (" " + active + " devices in ") : (" " + active + " device in ")) + states_array[state_device.state].name, 
          style: { fill: fill, r: state_device.count * 6 } 
        };

        return true;
      })
    }

    var labels = [];
    var data = [];
    if (stats.audit) {

      stats.audit.map((audit, index) => {
        labels.push(audit.date);
        data.push(audit.count);

        return true;
      });
    }

    const dashboardNASDAQChart = {
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            fill: false,
            borderColor: "#fbc658",
            backgroundColor: "transparent",
            pointBorderColor: "#fbc658",
            pointRadius: 4,
            pointHoverRadius: 8,
            pointBorderWidth: 8,
          },
        ],
      },
      options: {
        legend: {
          display: false,
          position: "top",
        },
      },
    };

    return (
      <>
        <div className="content">
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-bank text-warning" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Clinics</p>
                        <CardTitle tag="p">{stats.clinics}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-circle-10 text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Patients</p>
                        <CardTitle tag="p">{stats.patients}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-danger">
                        <i className="fas fa-drum-steelpan text-info" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Devices</p>
                        <CardTitle tag="p">{stats.devices}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-user-md text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Counselors</p>
                        <CardTitle tag="p">{stats.counselors}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Devices per state</CardTitle>
                </CardHeader>
                <CardBody>
                  <VectorMap 
                    zoomButtons={false}
                    zoomOnScroll={false}
                    map={'us_aea'} 
                    containerStyle={{
                      width: '100%',
                      height: '285px'
                    }}
                    regionStyle={{
                      initial: {
                        fill: "#cccccc",
                        "fill-opacity": 1,
                        stroke: "none",
                        "stroke-width": 0,
                        "stroke-opacity": 0
                      },
                      hover: {
                        "fill-opacity": 1,
                        fill: "#99ff99",
                        cursor: "pointer"
                      },
                      selected: {
                        fill: "#ff0000"
                      }
                    }}
                    backgroundColor="#fff"
                    markerStyle={{
                      initial: {
                        fill: "#4DAC26",
                        r: 10
                      }
                    }}
                    markers={states_devices_json}
                    markersSelectable={true}
                     />
                </CardBody>
                <CardFooter>
                  <div className="legend">
                    <i className="fa fa-circle text-success" /> Active {" "}
                    <i className="fa fa-circle text-danger" /> Inactive
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col md="8">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h5">Devices Events</CardTitle>
                  <p className="card-category">Number of events per day in last 14 days</p>
                </CardHeader>
                <CardBody>
                  <Line
                    data={dashboardNASDAQChart.data}
                    options={dashboardNASDAQChart.options}
                    width={400}
                    height={100}
                  />
                </CardBody>
                <CardFooter>
                  <div className="chart-legend">
                    <i className="fa fa-circle text-warning" /> Number of events in a day
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row style={{display: 'none'}}>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Users Behavior</CardTitle>
                  <p className="card-category">Last 24 Hours</p>
                </CardHeader>
                <CardBody>
                  <Line
                    data={dashboard24HoursPerformanceChart.data}
                    options={dashboard24HoursPerformanceChart.options}
                    width={400}
                    height={100}
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-history" /> Updated 3 minutes ago
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
